<template>
  <div class="pages">
    {{ $t('page') }}:
    <button
      class="pages-button"
      :disabled="page === 1"
      @click="setPage(-1)">
      <i class="pi pi-chevron-left" />
    </button><span class="pages-count">
      {{ page }} {{ $t('of') }} {{ transformPages }}
    </span>
    <button
      class="pages-button"
      :disabled="page === pages"
      @click="setPage(1)">
      <i class="pi pi-chevron-right" />
    </button>
  </div>
</template>

<script>
import queryFunction from '@/js/queryFunction';

export default {
  name: 'GoodsPagination',

  props: {
    page: {
      type: Number,
      default: 0,
    },

    pages: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    transformPages() {
      return queryFunction.tarnsformCount(this.pages);
    },
  },

  methods: {
    setPage(value) {
      this.$emit('fetchPage', value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.pages
  display: flex
  align-items: center

.pages-button
  border: 0
  background: none
  color: $blue-grey-500
  font-size: 8px
  &:disabled
    pointer-events: none
    opacity: 0.5

.pages-button i
  font-size: 14px

.pages-count
  margin: 0 4px
</style>