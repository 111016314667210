<template>
  <div
    :style="createCssVars"
    class="checkbox checkbox-custom-js"
    :class="{ 'disabled': isDisable }">
    <input
      type="checkbox"
      :checked="value"
      :disabled="isDisable">
    <span
      class="check-mark"
      :class="{ 'three-dot': isThreeDot }" />
  </div>
</template>

<script>
// Пример:
// <checkbox v-model="переменная" @input="событие"/>

// Или так: <checkbox :value="qqq" @click.native="qqq = $event"/>
export default {
  name: 'checkbox',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    isThreeDot: {
      type: Boolean,
      default: false,
    },

    isDisable: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default() {
        return this.$pimColors['$green-500'];
      },
    },
  },

  computed: {
    createCssVars() {
      return {
        '--bfb-checkbox-custom-color': this.color,
      };
    },
  },
};
</script>

<style lang="stylus" src="@/styles/checkbox-custom.styl" scoped></style>