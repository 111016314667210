<template>
  <paginate
    :value="page"
    :page-count="totalPages"
    :page-range="5"
    :margin-pages="1"
    :click-handler="changePage"
    prev-text="<i class='far fa-chevron-left'></i>"
    next-text="<i class='far fa-chevron-right'></i>"
    :container-class="pg.pagination"
    :active-class="pg['pagination__item--active']"
    :disabled-class="pg['pagination__item--disabled']"
    :prev-class="pg.pagination__item"
    :next-class="pg.pagination__item"
    :prev-link-class="pg['pagination__link--prev']"
    :next-link-class="pg['pagination__link--next']"
    :page-class="pg.pagination__item"
    :page-link-class="pg.pagination__link" />
</template>

<script>
import Paginate from 'vuejs-paginate';

export default {
  name: 'paginator',

  components: { Paginate },

  props: {
    // всего страниц
    totalPages: {
      type: Number,
      default: 0,
    },

    // текущая страница
    page: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    changePage(page) {
      this.$emit('change', page);
    },
  },
};
</script>

<style lang="stylus" module="pg">
.pagination
  display: flex
  margin: 0
  padding-left: 0
  list-style: none
  height: 24px

.pagination__item
  display: flex
  outline: none
  padding: 0
  &:nth-child(2)
    a
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
  &:nth-last-child(2)
    a
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px
  &--active
    a
      color: $white
      background: linear-gradient(0deg, $blue-600 0%, lighten($blue-600, 10%) 100%)
      border-color: $blue-400
  &--disabled
    a
      color: $blue-grey-400
      pointer-events: none
      background: $white

.pagination__link
  line-height: 24px
  padding: 0px 7px
  outline: none
  color: $blue-grey-900
  font-size: 13px
  font-weight: 500
  text-align: center
  background: linear-gradient(0deg, $grey-300 7 0%, $white 100%)
  border: 1px solid $grey-300
  &:hover
    text-decoration: none
  &--prev,
  &--next
    line-height: 24px
    padding: 0px 7px
    color: $blue-grey-900
    font-size: 13px
    font-weight: 500
    text-align: center
    background: linear-gradient(0deg, $grey-300 7 0%, $white 100%)
    border: 1px solid $grey-300
    i
      font-size: 13px
  &--prev
    margin-right: 5px
    margin-left: 0
    border-radius: 4px
  &--next
    margin-left: 5px
    border-radius: 4px
</style>