<template>
  <div
    v-show="!isLinked"
    class="ask"
    @click.stop>
    <span @click="createAsk">
      {{ $t('askTheClient') }}
    </span>
  </div>
</template>

<script>
import { SKU2RPC } from '@/js/constants';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Ask',

  props: {
    currentItemId: {
      type: Number,
      required: true,
    },

    distance: {
      type: Number,
      default: null,
    },

    position: {
      type: Number,
      default: null,
    },

    // Подтвержденные товары отображаются с другими визуальными маркерами
    isApproved: {
      type: Boolean,
      default: false,
    },

    // Статус is_linked
    isLinked: {
      type: Boolean,
      default: false,
    },

    // Совпадение в %
    distancePct: {
      type: Number,
      default: null,
    },

    // имя ключа для отправки Алгоритма поиска
    keyFilters: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      filters: state => state.filters,
      permissions: state => state.userProfile.permissions,
      updateColumsKey: state => state.updateColumsKey,
      isCanOnlySee: state => state.isCanOnlySee,
    }),
  },

  methods: {
    ...mapActions([
      'CREATE_ASK',
    ]),

    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    async createAsk() {
      const body = this.setRequestData();

      await this.CREATE_ASK({ body });

      // Обновление колонок (пока реалиазовано через загрузку всех столбцов)
      this.M_MUTATE_BY_KEY({
        key: 'updateColumsKey',
        data: this.updateColumsKey + 1,
      });

      this.$emit('closeModal');
    },

    setRequestData() {
      let pim_item_id = this.currentItemId;
      let store_item_id = this.filters.selectedId;

      if (this.filters.type === SKU2RPC) {
        pim_item_id = this.filters.selectedId;
        store_item_id = this.currentItemId;
      }

      return {
        // ID товара из PIM
        pim_item_id,
        // ID товара магазина
        store_item_id,
        distance: this.distance,
        distance_pct: this.distancePct,
        position: this.position,
        is_approved: this.isApproved,
        search_algorithm: this.filters[this.keyFilters + 'search_algorithm'],
        need_customer_decision: true,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
.ask
  display: flex
  width: 100%
  justify-content: flex-end
  align-items: center
  span
    cursor: pointer
    font-weight: bold
    color: $blue-500
    transition: all 0.3s
    &:hover
      color: $blue-700
</style>