<template>
  <div>
    <filters
      v-if="filters.account && stores.length && brands.length"
      :key="`${filters.account}_${filters.type}`"
      :filter-items="filterItems"
      @applyFilters="loadTable" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Filters from '@/assets/Filters';
import LIST_DATES from '@/js/calendar-options';

export default {
  name: 'IssueFilters',

  components: {
    Filters,
  },

  data() {
    return {
      action: null,
      dropdownLabel: this.$t('action'),
      status_list: [
        {
          value: undefined,
          name: this.$t('all'),
        },
        {
          value: 'MULTIPLE_SKU_RED',
          name: this.$t('MULTIPLE_SKU_RED'),
        },
        {
          value: 'DIFFERENT_RPC_RED',
          name: this.$t('DIFFERENT_RPC_RED'),
        },
        // {
        //   value: 'MULTIPLE_SKU_GREY',
        //   name: this.$t('MULTIPLE_SKU_GREY'),
        // },
        // {
        //   value: 'DIFFERENT_RPC_GREY',
        //   name: this.$t('DIFFERENT_RPC_GREY'),
        // },
        {
          value: 'TO_DECIDE',
          name: this.$t('TO_DECIDE'),
        },
      ],

      dropdownItems: [
        { name: this.$t('approve'), value: 'approved' },
        { name: this.$t('reject'), value: 'denied' },
      ],
    };
  },

  computed: {
    ...mapState([
      'stores',
      'brands',
      'filters',
      'issuer',
      'reloadTable',
      'modalBulkOperation',
    ]),
    ...mapState({
      permissions: state => state.userProfile.permissions,
    }),

    isDisabled() {
      let res = true;
      // Проверка по пермишенам
      if ('can_manage_links' in this.permissions) {
        res = !this.permissions.can_manage_links;

        if (res) {
          return res;
        }
      }

      // Проверка по колличеству выбранных товаров
      if (!this.modalBulkOperation.select_all) {
        res = this.modalBulkOperation.select_items.length === 0;
      }

      return res;
    },

    filterItems() {
      return {
        level1: [
          {
            type: 'search',
            storeName: 'global_term',
            placeholder: this.$t('search'),
          },
          {
            type: 'multiSelect',
            storeName: 'stores',
            options: this.stores,
            isNeedSearch: true,
            optionLabel: 'name',
            optionValue: 'id',
            placeholder: this.$t('stores'),
          },
          {
            type: 'multiSelect',
            storeName: 'brands',
            options: this.brands,
            isNeedSearch: true,
            optionLabel: 'brand',
            optionValue: 'id',
            placeholder: this.$t('brands'),
          },
        ],
        level2: [
          {
            type: 'dropdown',
            storeName: 'status',
            options: this.status_list,
            isNeedSearch: false,
            optionLabel: 'name',
            optionValue: 'value',
            placeholder: this.$t('status'),
          },
          {
            type: 'multiSelect',
            storeName: 'issuer',
            options: this.issuer,
            isNeedSearch: true,
            optionLabel: 'name',
            optionValue: 'name',
            placeholder: this.$t('authors'),
          },
          {
            type: 'calendar',
            storeName: 'created_date',
            showIcon: true,
            selectionMode: 'range',
            dateFormat: 'dd-mm-yy',
            isReset: true,
            showButtonBar: true,
            dateFrom: LIST_DATES[0].DATE_FROM,
            dateTo: LIST_DATES[0].DATE_TO,
            placeholder: this.$t('dateCreated'),
          },
          {
            type: 'calendar',
            storeName: 'reviewed_date',
            showIcon: true,
            selectionMode: 'range',
            dateFormat: 'dd-mm-yy',
            isReset: true,
            placeholder: this.$t('dateModified'),
          },
        ],
      };
    },
  },

  async created() {
    // получаем список пользователей для фильтра (авторы)
    await this.GET_AUTHORS();
    this.loadTable();
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    ...mapActions([
      'GET_AUTHORS',
    ]),

    showBulkOperationModal() {
      // данные обновились можно показывать модалку
      this.M_MUTATE_BY_KEY({
        key: 'modalBulkOperation.isOpen',
        data: true,
      });

      this.M_MUTATE_BY_KEY({
        key: 'modalBulkOperation.operation',
        data: this.action,
      });
    },

    loadTable() {
      this.M_MUTATE_BY_KEY({
        key: 'reloadTable',
        data: this.reloadTable + 1,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.grow.flex-end
  display: flex
  flex: 1 1 auto
  flex-direction: row
  align-content: center
  justify-content: flex-end
  align-items: center
  margin-left: 10px

.action-select
  ::v-deep .p-inputtext
    color: $white !important
    text-align: center

  ::v-deep .p-dropdown
    .p-dropdown-trigger
      color: $white !important

  ::v-deep .p-dropdown:not(.p-disabled).p-focus
    border-color: transparent
    box-shadow: none

  ::v-deep .p-inputtext:enabled:focus
    box-shadow: none

  ::v-deep .p-dropdown
    color: $white !important
    background: $blue-500 !important
    border: 1px solid $blue-500 !important

  ::v-deep .p-dropdown:hover
    background: $blue-700 !important
    border-color: $blue-700 !important

  ::v-deep .p-dropdown-panel
    border: 1px solid $blue-grey-100 !important
    box-shadow: 0 2px 4px rgba(120, 144, 156, 0.5)
</style>