<template>
  <div
    id="app"
    class="container">
    <app-header
      v-if="accounts.length" />

    <products v-if="filters.type==`rpc2sku` || filters.type==`sku2rpc`" />

    <approval v-if="filters.type == `linksManager` && isCanReadManageLinks" />

    <issue v-if="filters.type == `issue` && isCanReadManageLinks" />

    <modal-compare v-if="modalCompare.isOpen" />

    <modal-bulk-operations v-if="modalBulkOperation.isOpen" />

    <vue-easy-lightbox
      class="lightbox"
      :visible="!!mainImgLarge"
      :imgs="mainImgLarge"
      :index="0"
      @hide="handleHide" />
  </div>
</template>

<script>
import AppHeader from '@/assets/AppHeader';
import ModalCompare from '@/components/Modal/ModalCompare';
import ModalBulkOperations from '@/components/Modal/ModalBulkOperations';
import Products from '@/components/Page/products/Products';
import Approval from '@/components/Page/approval/Approval';
import Issue from '@/components/Page/issue/Issue';
import { mapState, mapActions, mapMutations } from 'vuex';
import primeVueLocales from '@/js/primeVueLocales';

export default {
  name: 'App',

  components: {
    AppHeader,
    ModalCompare,
    ModalBulkOperations,
    Products,
    Approval,
    Issue,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState([
      'accounts',
      'stores',
      'brands',
      'filters',
      'mainGoods',
      'modalCompare',
      'modalBulkOperation',
      'updateColumsKey',
      'mainImgLarge',
      'userProfile',
    ]),

    permissions() {
      return this.userProfile.permissions;
    },

    isCanReadManageLinks() {
      let res = false;

      if (this.permissions && 'can_read_manage_links_table' in this.permissions) {
        res = this.permissions.can_read_manage_links_table;
        res = true;
      }

      return res;
    },

    isCanOnlySee() {
      let res = true;
      // Если ему хоть что то разрешено то он может нажать
      for (const key in this.permissions) {
        if (this.permissions[key]) {
          res = false;
          break;
        }
      }
      return res;
    },
  },

  watch: {
    'userProfile.lang': {
      deep: true,
      handler(val) {
        this.changeLangPrime(val);
      },
    },
  },

  async created() {
    // получаем профиль
    await this.GET_USER_PROFILE();
    // получаем список аккаунтов
    await this.GET_ACCOUNTS();
    this.mutateIsCanOnlySee();
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    ...mapActions([
      'GET_ACCOUNTS',
      'GET_USER_PROFILE',
    ]),

    handleHide() {
      this.M_MUTATE_BY_KEY({
        key: 'mainImgLarge',
        data: null,
      });
    },

    mutateIsCanOnlySee() {
      this.M_MUTATE_BY_KEY({
        key: 'isCanOnlySee',
        data: this.isCanOnlySee,
        create: true,
      });
    },

    changeLangPrime(val) {
      // смена языка календаря происходит после того как с бека придет ответ
      // а само приложение начинает грузится только после того, как придет ответ от бека
      // ввиду этого приходится менять язык вот так

      this.$primevue.config.locale = primeVueLocales[val];
    },
  },
};
</script>

<style lang="stylus" scoped>
.container
  display: flex
  flex-direction: column
  height: 100vh
  min-height: 500px
  overflow: hidden
</style>