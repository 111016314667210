<template>
  <th
    v-if="column.show"
    :class="[
      columnHeader.wrapper,
      {[columnHeader.fixed]: column.fixed}
    ]"
    :width="column.width"
    :style="{ left: column.left }">
    <div :class="columnHeader.item">
      <!-- ТЕКСТОВОЕ ПРЕДСТАВЛЕНИЕ КОЛОНКИ -->
      <div :class="columnHeader.content">
        <!-- Нзвание колонки -->
        <span
          :class="columnHeader.label">
          {{ column.label }}
        </span>

        <!-- Слот кастомного содержимого -->
        <slot :name="'content-' + column.name" />
      </div>

      <!-- СОРТИРОВКА И ФИЛЬТРАЦИЯ -->
      <div :class="columnHeader.operations">
        <!-- Сортировка -->
        <div
          v-if="isSorting"
          :class="columnHeader.sort"
          @click="sort">
          <i
            class="fas fa-sort-up"
            :class="[
              columnHeader.sort__up,
              {[columnHeader.active]: column.sorting === 'up'}
            ]" />

          <i
            class="fas fa-sort-down"
            :class="[
              columnHeader.sort__down,
              {[columnHeader.active]: column.sorting === 'down'}
            ]" />
        </div>

        <!-- Слот для кнопки фильтр -->
        <slot name="filter" />
      </div>
    </div>
  </th>
</template>

<script>
export default {
  name: 'column-header',

  props: {
    // Настройки колонки (имя, ширина, ключ, ...)
    column: {
      type: Object,
      default: () => ({}),
    },

    // флаг, нужна ли сортировка
    isSorting: {
      type: Boolean,
      default: false,
    },

    // индекс колонки
    columnIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {};
  },

  methods: {
    sort() {
      this.$emit('sort', this.column.name, this.columnIndex);
    },
  },
};
</script>

<style lang="stylus" module="columnHeader">
.wrapper
  position: sticky
  top: 0
  padding: 12px 0
  color: $textColor
  background: $grey-50
  height: 49px
  z-index: 3
  box-shadow: inset 0px -1px 0px 0px $lightgrey
  &:last-child
    .item
      border-right: 0

.fixed
  position: sticky
  z-index: 4

.item
  display: flex
  align-items: center
  justify-content: space-between
  text-align: left;
  width: 100%
  height: 100%
  padding: 0 8px
  vertical-align: top
  color: $blue-grey-700
  font-weight: 500
  border-right: 1px solid $lightgrey

.content
  width:100%
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
  color: $blue-grey-400

.operations
  display: flex

.sort
  display: flex
  flex-direction: column
  color: $blue-grey-300
  cursor: pointer
  margin-left: 5px
  &:hover
    color: $blue-400
  &__up
    width: 10px
    height: 10px
    line-height: 16px !important
  &__down
    width: 10px;
    height: 10px;
    line-height: 0px !important
  .active
    color: $linkColor
</style>