<template>
  <div
    ref="container"
    class="dropdown">
    <div
      class="title"
      @click="onClick($event)">
      <span class="dropdown-label">
        {{ title }}
      </span>
      <div class="trigger">
        <span class="p-dropdown-trigger-icon pi pi-chevron-down" />
      </div>
    </div>
    <div
      v-if="overlayVisible"
      ref="overlay"
      class="panel">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ALDropdown',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      outsideClickListener: null,
      overlayVisible: false,
    };
  },

  beforeDestroy() {
    this.unbindOutsideClickListener();
  },

  methods: {
    onClick(event) {
      this.toggle();
      this.bindOutsideClickListener();
    },

    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.overlayVisible && this.$refs.overlay && !this.$refs.container.contains(event.target) && !this.$refs.overlay.contains(event.target)) {
            this.toggle();
          }
        };
        document.addEventListener('click', this.outsideClickListener);
      }
    },

    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },

    toggle() {
      this.overlayVisible = !this.overlayVisible;
    },
  },
};
</script>

<style lang="stylus" scoped>
.dropdown
  box-sizing: border-box
  background: $white
  border: 1px solid $blue-grey-100
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s
  border-radius: 3px
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
  font-size: 1rem
  font-weight: normal
  cursor: pointer
  position: relative
  user-select: none
  &:hover
    border-color: $blue-500
  &.disabled
    pointer-events: none
    cursor: default
    opacity: 0.8

.title
  display: flex

.dropdown-label
  background: transparent
  border: 0 none
  font-size: 0.875rem
  box-sizing: border-box
  display: block
  white-space: nowrap
  overflow: hidden
  flex: 1 1 auto
  width: 1%
  text-overflow: ellipsis
  cursor: pointer
  color: $blue-grey-700
  padding: 0.5rem 0.5rem
  font-weight: normal
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

.trigger
  background: transparent
  width: 2.357rem
  border-top-right-radius: 3px
  border-bottom-right-radius: 3px
  box-sizing: border-box
  display: flex
  align-items: center
  justify-content: center
  flex-shrink: 0
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
  font-size: 1rem
  font-weight: normal
  cursor: pointer

.panel
  z-index: 1011
  transform-origin: center top
  top: 37px
  left: 0
  width: 280px
  border: 1px solid $blue-grey-100
  box-shadow: 0 2px 4px rgb(120 144 156 / 50%)
  border-radius: 0.25rem
  min-width: 10rem
  box-sizing: border-box
  background: $white
  color: $blue-grey-700
  position: absolute
  padding: 20px 10px
</style>