<template>
  <tr
    :class="rowClass"
    class="row">
    <template v-if="isTableLoading">
      <td
        v-for="(col, i) in cols"
        :key="`column-index-${i}`"
        :style="{
          ...col.style,
          left: col.left
        }"
        :class="[
          c.cell,
          { [c.fixed]: col.fixed }
        ]">
        <skeleton
          width="100%"
          height="110px" />
      </td>
    </template>

    <template v-else>
      <cell
        v-for="(col, i) in cols"
        :key="`column-index-${i}`"
        :column="col"
        :index="index"
        :row="row"
        :style="{
          ...col.style,
          left: col.left
        }"
        :class="[
          c.cell,
          { [c.fixed]: col.fixed }
        ]" />

      <!-- Пустая ячейка (нужная для резинового последнего столбца) -->
      <td :class="c.cell" />
    </template>
  </tr>
</template>

<script>
import Cell from './Cell.js';
import Skeleton from 'primevue/skeleton';

export default {
  components: { Cell, Skeleton },

  props: {
    cols: Array,
    row: Object,
    index: Number,
    rowClass: String,
    isTableLoading: Boolean,
  },
};
</script>

<style lang="stylus" module="c">
.cell
  background: $white
  overflow: hidden
  padding: 8px
  vertical-align: top
  word-break: break-word
  box-shadow: inset  0px -1px 0px 0px $lightgrey

.fixed
  position: sticky
  box-shadow: inset -1px -1px 0px 0px $lightgrey
  z-index: 1
</style>