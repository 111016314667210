import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '@/App';
import { vars } from '@/js/additions';

Vue.use(VueRouter);

function getRoutes() {
  if (vars.integration) {
    return [
      {
        path: '/buyfeedback/linker',
        name: 'Home',
        component: App,
      },
    ];
  }
  return [
    {
      path: '/',
      name: 'Home',
      component: App,
    },
  ];
}

const router = new VueRouter({
  mode: 'history',
  routes: getRoutes(),
});

export default router;
