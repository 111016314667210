<template>
  <div class="search">
    <span class="p-input-icon-left p-input-icon-right search-wrapper"><i class="pi pi-search" />
      <input-text
        class="search-input"
        :disabled="disabledSearch"
        :value="value"
        :placeholder="`${$t('search')} ${searchPlaceholder}, ${$t('productName')}`"
        @input="inputSearch($event)" /><i
          v-show="value"
          class="pi pi-times pointer"
          @click="clearSearch" /></span>
  </div>
</template>

<script>
import { RPC2SKU, CANDIDATES_STORE } from '@/js/constants';
import { debounce } from '@/js/debounce';

export default {
  name: 'GoodsSearch',

  props: {
    type: {
      type: String,
      default: '',
    },

    storeId: {
      type: Number,
      default: null,
    },

    value: {
      type: String,
      required: true,
    },

    disabledSearch: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    searchPlaceholder() {
      return this.type === RPC2SKU || this.type === CANDIDATES_STORE ? 'RPC' : 'SKU';
    },
  },

  methods: {
    clearSearch() {
      this.inputSearch();
    },

    inputSearch: debounce(function (event) {
      this.$emit('searchLocal', {
        type: this.type,
        val: event,
        store: this.storeId,
      });
    }),
  },
};
</script>

<style lang="stylus" scoped>
.search
  margin-bottom: 24px

.search-wrapper
  width: 100%

.search-input
  width: 100%

::v-deep .p-inputtext
  font-size: 14px

::v-deep .p-inputtext:enabled:focus
  box-shadow: none
</style>