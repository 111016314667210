import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/js/i18n';
import request from '@/js/request';
import { LOADING, PER_PAGE } from '@/js/constants';
import queryFunction from '@/js/queryFunction';
import router from '@/router/index';
import { isEqual } from 'lodash-es';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // ключ на обновление столбцов
    updateColumsKey: 1,

    accounts: [],
    userProfile: {},
    stores: [],
    brands: [],
    issuer: [],
    // suppliers: [{
    //   id: 'all',
    //   name: i18n.t('all'),
    // }, {
    //   id: 'own',
    //   name: i18n.t('own'),
    // }, {
    //   id: 'rival',
    //   name: i18n.t('rival'),
    // }],

    // 1 колонка
    mainGoods: {
      results: [],
      count: null,
    },

    // RPC2SKU
    // 2 колонка
    rpc2skuCandidates: {
      results: [],
      count: null,
    },
    // 3 колонка
    rpc2skuLinked: {
      results: [],
      count: null,
    },

    // SKU2RPC
    // Колонки магазинов
    sku2rpcStores: {},

    // Данные для модального окна сравнения
    modalCompare: {
      info: null,
      pimInfo: null,
      statusLink: null,
      isOpen: false,
      isLoading: false,
    },

    // Данные для модального окна сравнения
    modalBulkOperation: {
      select_items: [],
      select_all: false,
      count: 0,
      operation: 'approved',
      isOpen: false,
    },

    // Основыные фильтры
    filters: queryFunction.defaultFiltersRpc2Sku(''),

    // фильтры 2 уровня
    isLevel2Open: false,

    // ТАБЛИЦА подтверждения связей
    approval_table: {
      table_details: {
        results: Array(30),
      },
      loadingRowsId: [],
    },

    // ТАБЛИЦА Issues
    issue_table: {
      table_details: {
        results: Array(30),
      },
      loadingRowsId: [],
    },

    updateTable: 0,
    reloadTable: 0,

    // Превью картинка
    mainImgLarge: null,
  },

  mutations: {
    // Универсальная мутация
    // @param {String} key - путь к объекту который нужно мутировать
    // @param {*} data - данные
    // @param {Boolean} create - создавать новый реактивный путь, если по исходному ошибка (?)
    M_MUTATE_BY_KEY(state, { key, data, create = false }) {
      const paths = key.split('.');
      let buffer = state;

      for (let i = 0; i < paths.length; i++) {
        // проверяем вложенность
        if (buffer[paths[i]] === undefined) {
          // если путь ошибочный, то пробуем проложить новый путь (если передан флаг)
          if (create) {
            Vue.set(buffer, paths[i], {});
          } else {
            // иначе ошибка
            console.warn('Error Mutation', key, data);
            break;
          }
        }

        // если последний участок пути
        if (i === paths.length - 1) {
          buffer[paths[i]] = data;
        } else {
          // если пусть существует  -> двигаемся дальше
          buffer = buffer[paths[i]];
        }
      }
    },
  },

  actions: {
    // получение списка аккаунтов
    async GET_ACCOUNTS({ state }, payload) {
      const res = await request({
        method: 'get',
        url: 'accounts/',
      });

      state.accounts = res;
    },

    // получение списка подключенных пауков в аккаунт
    async GET_STORES({ state }, payload) {
      const res = await request({
        method: 'get',
        url: `${payload.account}/stores/`,
      });

      state.stores = res;
    },

    // получение списка подключенных брендов в аккаунт
    async GET_BRANDS({ state }, payload) {
      const res = await request({
        method: 'get',
        url: `${payload.account}/brands/`,
      });

      state.brands = res;
    },

    // получение данных о пользователе
    async GET_USER_PROFILE({ state }, context, payload) {
      state.userProfile = await request({
        method: 'get',
        url: 'user-profile/',
      });

      // меняем язык
      i18n.locale = state.userProfile.lang;
    },

    // ----МОДАЛЬНОЕ ОКНО----
    // получаем расширенную информацию по конкретному товару
    async GET_GOOD_INFO({ state }, payload) {
      state.modalCompare.info = await request({
        method: 'get',
        url: `${state.filters.account}/linker/store/items/${payload.id}/`,
      });

      state.modalCompare.info.type = payload.type;
    },

    // получение деталей по товару из PIM
    async GET_GOOD_INFO_PIM({ state }, payload) {
      state.modalCompare.pimInfo = await request({
        method: 'get',
        url: `${state.filters.account}/linker/pim/items/${payload.id}/`,
      });

      state.modalCompare.pimInfo.type = payload.type;
    },

    // получение списка товаров магазина
    async GET_GOODS({ state }, payload) {
      state.mainGoods.results = Array(5).fill({ type: LOADING });
      state.mainGoods = await request({
        method: 'post',
        url: `${state.filters.account}/linker/store/items/`,
        params: { page: state.filters.page },
        body: payload.body,
      });

      state.mainGoods.pages = Math.ceil(state.mainGoods.count / state.mainGoods.results.length);
    },

    // получение списка товаров из PIM
    async GET_GOODS_PIM({ state }, payload) {
      state.mainGoods.results = Array(5).fill({ type: LOADING });
      state.mainGoods = await request({
        method: 'post',
        url: `${state.filters.account}/linker/pim/items/`,
        params: { page: state.filters.page },
        body: payload.body,
      });

      state.mainGoods.pages = Math.ceil(state.mainGoods.count / state.mainGoods.results.length);
    },

    // спрашиваем клиента
    async CREATE_ASK({ state }, payload) {
      return await request({
        method: 'post',
        url: `${state.filters.account}/linker/links/link/`,
        body: payload.body,
      });
    },

    // создаем кандидата на связывание
    async CREATE_CANDIDATE({ state }, payload) {
      return await request({
        method: 'post',
        url: `${state.filters.account}/linker/links/link/`,
        body: payload.body,
      });
    },

    // разрываем связь между парой товаров
    async DESTROY_CANDIDATE({ state }, payload) {
      return await request({
        method: 'post',
        url: `${state.filters.account}/linker/links/unlink/`,
        body: payload.body,
      });
    },

    // получить кандидатов (товары из PIM) на связывание
    async GET_RPC2SKU_CANDIDATES({ state }) {
      state.rpc2skuCandidates.results = Array(5).fill({ type: LOADING });

      state.rpc2skuCandidates.results = await request({
        method: 'post',
        url: `${state.filters.account}/linker/candidates/sku/${state.filters.selectedId}/`,
        body: {
          sub_term: state.filters.candidate_sub_term || undefined,
          search_algorithm: state.filters.candidate_search_algorithm || undefined,
          distance: state.filters.distance,
        },
      });
    },

    // получение списка связанных товаров
    async GET_LINKED_GOODS({ state }) {
      state.rpc2skuLinked.results = Array(5).fill({ type: LOADING });

      state.rpc2skuLinked.results = await request({
        method: 'post',
        url: `${state.filters.account}/linker/store/linked/${state.filters.selectedId}/`,
        body: {
          sub_term: state.filters.linked_sub_term || undefined,
        },
      });
    },

    // получить кандидатов (товары магазина) на связывание
    async GET_SKU2RPC_CANDIDATE({ state }, payload) {
      state.sku2rpcStores[payload.store].results = Array(5).fill({ type: LOADING });

      state.sku2rpcStores[payload.store].results = await request({
        method: 'post',
        url: `${state.filters.account}/linker/candidates/rpc/${state.filters.selectedId}/store/${payload.store}/`,
        body: {
          sub_term: state.filters['store_' + payload.store + '_sub_term'] || undefined,
          search_algorithm: state.filters['store_' + payload.store + '_search_algorithm'] || undefined,
          distance: state.filters.distance,
          actual_date_from: state.filters.actual_date_from,
          actual_date_to: state.filters.actual_date_to,
          created_date_from: state.filters.created_date_from,
          created_date_to: state.filters.created_date_to,
        },
      });
    },

    // сообщить что нет подходящих кандидатов
    async SEND_NO_CANDIDATES({ state }, payload) {
      return await request({
        method: 'post',
        url: `${state.filters.account}/linker/complain/${state.filters.type}/`,
        body: payload.body,
      });
    },

    // Получить данные по таблице
    async A_GET_TABLE_APPROVAL({ state, getters }, options) {
      const res = await request({
        method: 'post',
        url: `${state.filters.account}/linker/manage/links/`,
        body: {
          ...state.filters,
          page_num: options.page_num,
          page_size: PER_PAGE,
          order_by: options.order_by,
        },
      });

      state.approval_table.table_details = res;
    },

    // Получить данные по таблице
    async A_GET_TABLE_ISSUE({ state, getters }, options) {
      const res = await request({
        method: 'post',
        url: `${state.filters.account}/linker/manage/issues/`,
        body: {
          ...state.filters,
          page_num: options.page_num,
          page_size: PER_PAGE,
          order_by: options.order_by,
        },
      });

      state.issue_table.table_details = res;
    },

    // получение списка авторов
    async GET_AUTHORS({ state }, payload) {
      const res = await request({
        method: 'get',
        url: `${state.filters.account}/linker/manage/issuers/`,
      });

      state.issuer = res.map(el => { return { name: el }; });
    },

    // Отправка подтверждения апрува (единичная операция)
    async A_SEND_APPROVE({ state, commit }, payload) {
      // масив выбранных связей формата ${pim_item_id}_${store_item_id}
      const array = payload.items[0].split('_');
      const nameTable = payload.table;

      const res = await request({
        method: 'PATCH',
        url: `${state.filters.account}/linker/candidates/approve/`,
        body: {
          pim_item_id: array[0],
          store_item_id: array[1],
        },
      });

      // Очищаем лоудер конкретной строки
      let index = state[nameTable].loadingRowsId.findIndex((item) => {
        return item === `${res.pim_item_id}_${res.store_item_id}`;
      });

      state[nameTable].loadingRowsId.splice(index, 1);

      // Меняем строку в таблице
      index = state[nameTable].table_details.results.findIndex((item) => {
        return item.pim_item_id === res.pim_item_id && item.store_item_id === res.store_item_id;
      });

      state[nameTable].table_details.results.splice(index, 1, res);
    },

    // Отправка опровержение апрува (единичная операция)
    async A_SEND_DENIED({ state }, payload) {
      // масив выбранных связей формата ${pim_item_id}_${store_item_id}
      const array = payload.items[0].split('_');
      const nameTable = payload.table;

      const res = await request({
        method: 'PATCH',
        url: `${state.filters.account}/linker/candidates/disapprove/`,
        body: {
          pim_item_id: array[0],
          store_item_id: array[1],
        },
      });

      // Очищаем лоудер конкретной строки
      let index = state[nameTable].loadingRowsId.findIndex((item) => {
        return item === `${res.pim_item_id}_${res.store_item_id}`;
      });

      state[nameTable].loadingRowsId.splice(index, 1);

      // Меняем строку в таблице
      index = state[nameTable].table_details.results.findIndex((item) => {
        return item.pim_item_id === res.pim_item_id && item.store_item_id === res.store_item_id;
      });

      state[nameTable].table_details.results.splice(index, 1, res);
    },

    // Отправка подтверждения апрува (массовая операция)
    async A_SEND_BULK_APPROVE({ state }, payload) {
      // масив выбранных связей формата ${pim_item_id}_${store_item_id}
      const items = payload.items.map(el => {
        const array = el.split('_');
        return { pim_item_id: array[0], store_item_id: array[1] };
      });

      await request({
        method: 'PATCH',
        url: `${state.filters.account}/linker/candidates/bulk-approve/`,
        body: {
          ...state.filters,
          items,
          select_all: payload.select_all,
        },
      });
    },

    // Отправка отклонения апрува (массовая операция)
    async A_SEND_BULK_DENIED({ state }, payload) {
      // масив выбранных связей формата ${pim_item_id}_${store_item_id}
      const items = payload.items.map(el => {
        const array = el.split('_');
        return { pim_item_id: array[0], store_item_id: array[1] };
      });

      await request({
        method: 'PATCH',
        url: `${state.filters.account}/linker/candidates/bulk-disapprove/`,
        body: {
          ...state.filters,
          items,
          select_all: payload.select_all,
        },
      });
    },
  },
});

store.watch(
  state => state.filters,
  value => {
    if (!isEqual(router.currentRoute.query, { ...queryFunction.prepareToUrl(value) })) {
      router.push({
        name: 'Home',
        query: { ...queryFunction.prepareToUrl(value) },
      });
    }
  },
  {
    deep: true,
  }
);

export default store;