var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"content"},[_c('div',{staticClass:"app-main cols"},[_c('c-table-v3',{ref:"table",attrs:{"rows":_vm.rowsWithLoader,"total-count":_vm.table.count,"total-pages":_vm.totalPages},on:{"paginate":_vm.eventPaginate,"sort":_vm.eventSort}},[_c('c-table-column-v3',{attrs:{"fixed":"","width":"210px","name":"status","label":_vm.$t('agreementStatus'),"custom-class":'buffer_column'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var pim_item_id = ref.pim_item_id;
var store_item_id = ref.store_item_id;
return [_c('status-action-candidates-issue',{attrs:{"list":[(pim_item_id + "_" + store_item_id)],"status":status},on:{"change":_vm.afterChangeStatus}})]}}])}),_c('c-table-column-v3',{attrs:{"width":"350px","name":"StoreInfo","label":_vm.$t('storeItem')},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('product-info',{attrs:{"obj":{
              title: row.title,
              rpc: row.rpc,
              url: row.url,
              image_preview: row.cache_image.preview || row.image,
              image_table: row.cache_image.table || row.image,
              brand: row.brand,
            }},on:{"showModalCompare":function($event){return _vm.showModalCompare(row)}}})]}}])}),_c('c-table-column-v3',{attrs:{"width":"350px","name":"AccountInfo","label":_vm.$t('accountItem')},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('product-info',{attrs:{"obj":{
              title:row.pim_title,
              sku:row.sku,
              url:row.pim_link,
              image_preview: row.pim_image,
              image_table: row.pim_image,
              brand:row.pim_brand,
            }},on:{"showModalCompare":function($event){return _vm.showModalCompare(row)}}})]}}])}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('store'),"name":"store"}}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('dateCreated'),"name":"created_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var created_at = ref.created_at;
return [_c('span',[_vm._v(_vm._s(created_at.split(' ')[0]))])]}}])}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('author'),"name":"issuer"}}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('searchAlgorithm'),"width":"250px","name":"search_algorithm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var search_algorithm = ref.search_algorithm;
return [_c('span',[_vm._v(_vm._s(search_algorithm.toUpperCase()))])]}}])}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('percentMatch'),"name":"distance_pct"}}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('rankPosition'),"name":"position"}}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('dateModified'),"name":"reviewed_at","sort":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var reviewed_at = ref.reviewed_at;
return [_c('span',[_vm._v(_vm._s(reviewed_at.split(' ')[0]))])]}}])}),_c('c-table-column-v3',{attrs:{"label":_vm.$t('authorChanges'),"name":"reviewer","sort":false}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }