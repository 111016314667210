<template>
  <div class="search">
    <span class="p-input-icon-left p-input-icon-right search-wrapper">
      <i class="pi pi-search" />
      <input-text
        v-model.trim="localFilters[data.storeName]"
        class="search-input"
        :placeholder="data.placeholder"
        @input="$emit('eventInput')" />
      <i
        v-show="localFilters[data.storeName]"
        class="pi pi-times pointer"
        @click="$emit('eventClick', data.storeName)" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Search',

  props: {
    // Основные данные
    data: {
      type: Object,
      default: () => ({}),
    },

    // Локальный фильтр
    localFilters: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="stylus" scoped>
.search-wrapper
  min-width: 150px
  width: 100%

.search-input
  width: 100%
</style>