<template>
  <div
    :id="_uid+'-panel'"
    class="panel panel-js">
    <slot />
  </div>
</template>

<script>
// Вообще в проекте есть футер id=bq-main-footer, по дефолту он скрыт пока в нем ничего нет.
// Здесь идея заключается в создании атомарного vue к-та, который можно юзать везде

export default {
  name: 'panel',

  props: {
    position: {
      type: String,
      default: 'top',
    },

    // Указываем родителя - куда именно вставлять эту плашку
    // Если родитель не указан, то вставляем в место инициализации
    parent: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      elParent: null,
    };
  },

  mounted() {
    this.elParent = this.parent.length ? document.querySelector(this.parent) : null;

    if (this.elParent) {
      if (this.position === 'top') {
        this.elParent.prepend(this.$el);
      } else if (this.position === 'bottom') {
        this.elParent.append(this.$el);
      }
    }
  },

  beforeDestroy() {
    if (this.elParent) {
      this.elParent.removeChild(this.$el);
    }
  },
};
</script>

<style lang="stylus" scoped>
.panel
  display: flex
  align-items: center
  justify-content: flex-start
  flex-direction: row
  flex-basis: 49px
  flex-shrink: 0
  flex-grow: 0
  padding: 8px 12px
  border-top: 1px solid $blue-grey-100
  border-bottom: 1px solid $blue-grey-100
  background-color: $white
</style>