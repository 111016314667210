var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"row",class:_vm.rowClass},[(_vm.isTableLoading)?_vm._l((_vm.cols),function(col,i){
var _obj;
return _c('td',{key:("column-index-" + i),class:[
        _vm.c.cell,
        ( _obj = {}, _obj[_vm.c.fixed] = col.fixed, _obj )
      ],style:(Object.assign({}, col.style,
        {left: col.left}))},[_c('skeleton',{attrs:{"width":"100%","height":"110px"}})],1)}):[_vm._l((_vm.cols),function(col,i){
      var _obj;
return _c('cell',{key:("column-index-" + i),class:[
        _vm.c.cell,
        ( _obj = {}, _obj[_vm.c.fixed] = col.fixed, _obj )
      ],style:(Object.assign({}, col.style,
        {left: col.left})),attrs:{"column":col,"index":_vm.index,"row":_vm.row}})}),_c('td',{class:_vm.c.cell})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }