var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"link-button"},[(_vm.status)?_c('i',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      theme: 'light',
      delay: [500, 0],
      arrow: true,
      arrowType: 'round',
      animation: 'fade',
      content: _vm.approvedText
    }),expression:"{\n      theme: 'light',\n      delay: [500, 0],\n      arrow: true,\n      arrowType: 'round',\n      animation: 'fade',\n      content: approvedText\n    }"}],class:_vm.approvedClass}):_vm._e(),(!_vm.isCanOnlySee)?_c('div',{staticClass:"link-title"},[_vm._v(" "+_vm._s(_vm.$t('link'))+": ")]):_vm._e(),(!_vm.isCanOnlySee)?_c('tippy',{attrs:{"content":_vm.contentTippy(_vm.value)},on:{"show":function () { return _vm.isDisable; }}},[_c('input-switch',{attrs:{"disabled":_vm.isDisable},on:{"change":_vm.changeLinkStatus,"click":function($event){$event.stopPropagation();}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }