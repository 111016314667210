<template>
  <div class="issue_page">
    <issue-filters />
    <issue-table v-if="filters.account && stores.length && brands.length" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IssueFilters from './IssueFilters';
import IssueTable from './IssueTable';

export default {
  name: 'Issue',

  components: {
    IssueFilters,
    IssueTable,
  },

  computed: {
    ...mapState([
      'stores',
      'brands',
      'filters',
    ]),
  },
};
</script>

<style lang="stylus" scoped>
.issue_page
  display: flex
  flex-direction: column
  flex: 1 0 0%
  min-height: 0
</style>