import types from './types';

export default function () {
  return {
    namespaced: true,

    state() {
      return {
        name: '',
        charts: [],
        loading: true,
        search: '',
        page_num: 1,
        columns: [], // фильтры
        order_by: '', // сортировка
      };
    },

    actions: {
      // сбросить все на дефолт
      [types.a.CLEAR_STATE]({ commit }) {
        commit(types.m.SET_PAGE, 1);
        commit(types.m.SET_SEARCH, '');
        commit(types.m.SET_FILTERS, { key: 'full-reset' });
      },

      // Переключаем видимость графика
      async [types.a.TOGGLE_CHART]({ state, dispatch }, payload) {
        state.charts.splice(payload.index, 1, payload);
      },
    },

    mutations: {
      // Универсальная мутация
      // Работает на одну вложенность
      // На большую глубину смысла делать не было
      [types.m.SET](state, { key, data }) {
        if (!(key in state)) {
          return console.error(`The key: ${key} is missing in the store!`);
        }

        state[key] = data;
      },

      // Изменение страницы
      [types.m.SET_PAGE](state, payload) {
        state.page_num = payload;
      },

      // Изменение поисковой строки
      [types.m.SET_SEARCH](state, payload) {
        state.search = payload;
      },

      // Изменение табличных фильтров
      [types.m.SET_FILTERS](state, { key, val }) {
        if (key === 'filters') {
          // добавление или изменение фильтра
          // находим нужный индекс
          const index = state.columns.findIndex(el => el.name === val.name);

          // если есть данный фильтр, то удаляем его
          if (index >= 0) {
            state.columns.splice(index, 1);
          }

          // добавляем фильтр
          state.columns.push(val);
        } else if (key.includes('reset-')) {
          // частичный сброс фильтров
          const cropKey = key.replace('reset-', '');
          // находим нужный индекс
          const index = state.columns.findIndex(el => el.name === cropKey);

          // если есть данный фильтр, то удаляем его
          if (index >= 0) {
            state.columns.splice(index, 1);
          }
        } else if (key === 'full-reset') {
          // полный сброс фильтров
          state.columns = [];
          state.order_by = '';
        } else if (key === 'sort') {
          state.order_by = val;
        }
      },
    },

    getters: {
      // получаем ссылку на локал стор
      [types.g.GET_NAME](state) {
        return state.name;
      },

      // получаем страницу
      [types.g.GET_PAGE](state) {
        return state.page_num;
      },

      // получаем серч
      [types.g.GET_SEARCH](state) {
        return state.search;
      },

      // получаем фильтры
      [types.g.GET_FILTER](state) {
        return state.columns;
      },

      // получаем сортировку
      [types.g.GET_SORT](state) {
        return {
          inner: state.order_by,
          public: state.order_by.replace('-', ''),
          // position: state.order_by[0] === '-' ? '-' : '',
        };
      },

      // получаем графики
      [types.g.GET_CHARTS](state) {
        return state.charts;
      },
    },
  };
}