<template>
  <div class="link-button">
    <i
      v-if="status"
      v-tippy="{
        theme: 'light',
        delay: [500, 0],
        arrow: true,
        arrowType: 'round',
        animation: 'fade',
        content: approvedText
      }"
      :class="approvedClass" />

    <div
      v-if="!isCanOnlySee"
      class="link-title">
      {{ $t('link') }}:
    </div>

    <tippy
      v-if="!isCanOnlySee"
      :content="contentTippy(value)"
      @show="() => isDisable">
      <input-switch
        v-model="value"
        :disabled="isDisable"
        @change="changeLinkStatus"
        @click.stop />
    </tippy>
  </div>
</template>

<script>
import { SKU2RPC } from '@/js/constants';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'LinkerSwitch',

  props: {
    status: {
      type: Boolean,
      default: false,
    },

    currentItemId: {
      type: Number,
      required: true,
    },

    type: {
      type: String,
      default: '',
    },

    distance: {
      type: Number,
      default: null,
    },

    position: {
      type: Number,
      default: null,
    },

    // Подтвержденные товары отображаются с другими визуальными маркерами
    isApproved: {
      type: Boolean,
      default: false,
    },

    // Статус is_linked
    isLinked: {
      type: Boolean,
      default: false,
    },

    // Совпадение в %
    distancePct: {
      type: Number,
      default: null,
    },

    // имя ключа для отправки Алгоритма поиска
    keyFilters: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      value: false,
    };
  },

  computed: {
    ...mapState({
      filters: state => state.filters,
      permissions: state => state.userProfile.permissions,
      updateColumsKey: state => state.updateColumsKey,
      isCanOnlySee: state => state.isCanOnlySee,
    }),

    isDisable() {
      let res = false;

      // Если свитчер выключен
      if (!this.value) {
        // Могу ли создать связи
        res = !this.permissions.can_create_not_approved_link;
        if (this.isApproved) {
          res = !this.permissions.can_create_approved_link;
        }
      } else {
        // Если свитчер включен
        if (this.isLinked) {
          if (this.isApproved) {
            res = !this.permissions.can_delete_approved_link;
          } else {
            res = !this.permissions.can_delete_not_approved_link;
          }
        }
      }

      return res;
    },

    approvedText() {
      let res = this.$t('comConfirmNot');

      if (this.isApproved) {
        res = this.$t('comConfirm');
      }

      return res;
    },

    approvedClass() {
      let res = 'far fa-check-circle';

      if (!this.isApproved) {
        res = 'fas fa-exclamation-circle';
      }

      return res;
    },
  },

  created() {
    this.value = this.status;
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    ...mapActions([
      'CREATE_CANDIDATE',
      'DESTROY_CANDIDATE',
    ]),

    async changeLinkStatus() {
      const body = this.setRequestData();

      this.value
        ? await this.CREATE_CANDIDATE({ body })
        : await this.DESTROY_CANDIDATE({ body });

      // Обновление колонок (пока реалиазовано через загрузку всех столбцов)
      this.M_MUTATE_BY_KEY({
        key: 'updateColumsKey',
        data: this.updateColumsKey + 1,
      });

      this.$emit('closeModal');
    },

    setRequestData() {
      let pim_item_id = this.currentItemId;
      let store_item_id = this.filters.selectedId;

      if (this.filters.type === SKU2RPC) {
        pim_item_id = this.filters.selectedId;
        store_item_id = this.currentItemId;
      }

      return {
        // ID товара из PIM
        pim_item_id,
        // ID товара магазина
        store_item_id,
        distance: this.distance,
        distance_pct: this.distancePct,
        position: this.position,
        is_approved: this.isApproved,
        search_algorithm: this.filters[this.keyFilters + 'search_algorithm'],
      };
    },

    contentTippy(value) {
      return value ? this.$t('noAccessBreak') : this.$t('noAccessCeate');
    },
  },
};
</script>

<style lang="stylus" scoped>
.link-button
  display: flex
  align-items: center
  gap: 8px

::v-deep .p-inputswitch-slider:not(.p-disabled)
  box-shadow: none !important

.fa-exclamation-circle
  color: $orange-500

.fa-check-circle
  color: $green-500
</style>