<template>
  <div class="modal">
    <div class="wrapper">
      <header class="header">
        <div class="title">
          {{ headerText }}
        </div>
        <div class="right">
          <div
            class="close-modal"
            @click="closeModal">
            <i class="pi pi-times" />
          </div>
        </div>
      </header>
      <div
        class="cols">
        <div
          class="m-label warning">
          <span>{{ $t('selected') + ": " + modalBulkOperation.count }}</span>
        </div>
      </div>
      <footer
        class="footer">
        <div
          class="btn btn-blue"
          :disabled="disabled"
          @click="submitModal">
          {{ $t('apply') }}
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'ModalBulkOperations',

  data() {
    return {
      disabled: false,
    };
  },

  computed: {
    ...mapState({
      updateTable: state => state.updateTable,
      modalBulkOperation: state => state.modalBulkOperation,
    }),

    headerText() {
      let res = this.$t('bulkOperation') + ': ';

      if (this.modalBulkOperation.operation === 'approved') {
        res = res + this.$t('approve');
      } else if (this.modalBulkOperation.operation === 'denied') {
        res = res + this.$t('reject');
      }

      return res;
    },
  },

  methods: {
    ...mapActions([
      'A_SEND_BULK_APPROVE',
      'A_SEND_BULK_DENIED',
    ]),

    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    closeModal() {
      this.M_MUTATE_BY_KEY({
        key: 'modalBulkOperation.isOpen',
        data: false,
      });
    },
    // =================== Эвенты модалки ===================
    async submitModal() {
      this.disabled = true;
      const obj = {
        items: this.modalBulkOperation.select_items,
        select_all: this.modalBulkOperation.select_all,
      };
      if (this.modalBulkOperation.operation === 'approved') {
        await this.A_SEND_BULK_APPROVE(obj);
      } else {
        await this.A_SEND_BULK_DENIED(obj);
      }

      this.disabled = false;
      // Обновляем таблицу
      this.M_MUTATE_BY_KEY({
        key: 'updateTable',
        data: this.updateTable + 1,
      });

      // Закрываем модалку сравнения
      this.closeModal();
    },
  },
};
</script>

<style lang="stylus" scoped>
.modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  background: rgba(0, 0, 0, 0.5)
  padding: 23px
  z-index: 9

.wrapper
  display: flex
  flex-direction: column
  position: relative
  z-index: 3
  border-radius: 4px
  box-shadow: 0 2px 4px rgba(120, 144, 156, 0.5)
  background: $white
  overflow: auto

.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 8px 16px

.title
  font-weight: 300
  font-size: 20px
  line-height: 24px
  color: $blue-grey-700

.right
  display: flex
  flex-direction: row-reverse
  width: 100px

.cols
  display: flex
  justify-content: space-between
  align-content: stretch
  flex: 1 0 0
  padding: 8px 16px
  .warning
    border-radius: 4px
    background: $orange-50
    border: none
    padding: 5px 10px

.footer
  display: flex
  justify-content: flex-end
  padding: 8px 16px
  border-radius: 0 0 4px 4px
</style>