<template>
  <div class="full">
    <div class="images">
      <gallery :images="item.images" />
    </div>
    <div class="content">
      <table class="options">
        <tr class="option-type">
          <td class="option-title">
            {{ $t('type') }}:
          </td>
          <td class="option-value">
            <span class="type">
              {{ itemTypeTitle }}
            </span>
          </td>
        </tr>
        <tr class="option-main">
          <td class="option-title">
            {{ $t('denomination') }}:
          </td>
          <td class="option-value">
            <div>{{ item.name }}</div>
          </td>
        </tr>
        <tr
          v-if="item.url || item.pim_url"
          class="option-main option-type">
          <td class="option-title">
            {{ $t('source') }}:
          </td>
          <td class="option-value">
            <div>
              <a
                :href="item.url || item.pim_url"
                target="_blank">
                {{ cropUrl(item.url || item.pim_url) }}
              </a>
            </div>
          </td>
        </tr>
        <tr class="option-attr">
          <td class="option-title">
            {{ item.rpc ? 'RPC' : 'SKU' }}:
          </td>
          <td class="option-value">
            {{ item.rpc ? item.rpc : item.sku }}
          </td>
        </tr>
        <tr
          v-for="(attr, key) in mappedAttr"
          :key="key"
          class="option-attr">
          <td class="option-title">
            {{ key }}:
          </td>
          <td class="option-value">
            {{ attr }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { RPC2SKU, SKU2RPC, CANDIDATES_STORE, CANDIDATES_PIM, LINKED } from '@/js/constants';
import Gallery from '@/components/Modal/Gallery';
import queryFunction from '@/js/queryFunction';

export default {
  name: 'ItemCard',

  components: {
    Gallery,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    mappedAttr() {
      let attributes = this.item.attributes;
      if (this.item.sku) {
        attributes = Object.fromEntries(
          Object.values(this.item.attributes)
            .filter((item) => Array.isArray(item) && item.length)
            .map((item) => {
              let value = item[0].value;
              if (Array.isArray(item[0].value)) {
                value = item[0].value.join(',');
              }
              return [item[0].name, value];
            }));
      }
      return attributes;
    },

    itemTypeTitle() {
      let title;
      switch (this.item.type) {
      case RPC2SKU:
        title = this.$t('prodFromStore');
        break;
      case SKU2RPC:
        title = this.$t('prodFromPIM');
        break;
      case CANDIDATES_STORE:
        title = this.$t('candFromStore');
        break;
      case CANDIDATES_PIM:
        title = this.$t('candFromPIM');
        break;
      case LINKED:
        title = this.$t('prodFromPIM');
        break;
      }

      return title;
    },
  },

  methods: {
    cropUrl: queryFunction.cropUrl,
  },
};
</script>

<style lang="stylus" scoped>
.full
  display: flex
  flex-direction: column
  height: 100%

.images
  margin-bottom: 8px

.content
  flex: 1 0 0
  background: $white
  border-radius: 4px
  padding: 12px
  overflow: auto

.type
  display: inline-block
  padding: 4px 8px
  background: $blue-500
  border-radius: 4px
  color: $white
  font-weight: 900
  font-size: 11px
  line-height: 13px
  text-transform: uppercase

.options
  width: 100%
  font-size: 13px

.option-type
  border-bottom: 1px solid $blue-grey-100

.option-main
  font-weight: 500

.option-title
  vertical-align: top
  width: 25%
  padding: 12px 0

.option-value
  vertical-align: top
  padding: 12px 0 12px 12px
  div
    word-wrap: anywhere
    line-height: 16px
    min-height: 32px
</style>