<template>
  <article
    :id="name"
    :data-name="name"
    class="wrap">
    <!-- Основное содержимое -->
    <div :class="['wrap__main-wrap']">
      <!-- ============ Таблица ============ -->
      <div
        v-show="!hideTable"
        :class="[containerTableClass, {'not-empty': rows.length}]"
        class="wrap__container">
        <!-- Сама таблица -->
        <div
          class="table__wrapper">
          <table
            :class="tableClass"
            class="table__table">
            <thead>
              <tr
                :class="rowClass"
                class="table__header-row">
                <cell-header
                  v-for="(col, i) in cols"
                  :key="`column-index-${i}`"
                  :column="col"
                  :column-index="i"
                  :is-sorting="!isLoading && col.sort"
                  @sort="changeSort">
                  <!-- Кастомное содержимое для для шапок -->
                  <template :slot="'content-' + col.name">
                    <slot :name="'content-' + col.name" />
                  </template>
                </cell-header>

                <!-- Резиновая ячейка -->
                <th class="table__buffer-th" />
              </tr>
            </thead>

            <tbody
              v-if="rows.length"
              :class="tableBodyClass">
              <template v-for="(row, i) in rows">
                <!-- Основная строка с данными -->
                <row
                  :key="`row-base-${i}`"
                  :ref="'row-' + i"
                  :cols="cols"
                  :row="row"
                  :is-table-loading="isLoading || row.isloading"
                  :index="i"
                  :row-class="rowClass"
                  :class="[rowClass]" />

                <!-- Строка с графиком -->
                <tr
                  v-if="chartList[i] && chartList[i].isOpen"
                  :key="`row-chart-${i}`"
                  :class="rowClass"
                  class="table__row-hash">
                  <td
                    :index="i"
                    :colspan="cols.length - unselectedColumns.length + 1">
                    <div
                      class="table__row-sticky"
                      :style="{ width: widthForChart + 'px' }">
                      <highcharts :options="chartList[i].chart" />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <!-- Уведомление, если ничего не найдено или первая прогрузка -->
        <div
          v-if="!rows.length && !isLoading"
          class="warning">
          <div class="wrap_nothing">
            <span>{{ $t('notFound') }}</span>
          </div>
        </div>
      </div>

      <!-- ============ Слот для каталожного вида ============ -->
      <slot name="catalog" />

      <!-- Наши фантомные элементы колонок, которые потом распарсиваются -->
      <div
        class="js-fantoms"
        hidden>
        <!-- Дефолтный слот, куда прокидывается все данные -->
        <slot />
      </div>

      <!-- ============ ПАНЕЛЬ - ПОДВАЛ ============ -->
      <panel class="brbn footer">
        <!-- Кол-во товаров -->
        <span class="footer_goods">
          {{ $t('found') }}:
          <b>{{ totalCount }}</b>
        </span>

        <div class="footer_slot">
          <slot name="before_paginator" />
        </div>

        <paginatorv2
          v-if="totalPages > 1 && rows.length > 0"
          class="footer_paginator"
          :total-pages="totalPages"
          :page="currentPage"
          @change="changePage" />

        <!-- Переключатель товаров -->
        <!-- <button
          class="btn btn-sm footer_pages">
          {{ $trans.templates.onPage }}: 30
        </button> -->
      </panel>
    </div>
  </article>
</template>

<script>
import Paginatorv2 from '../Paginatorv2';
import CellHeader from './CellHeader.vue';
import Row from './Row';
import Cell from './Cell.js';

import localStoreTable from './localStoreTable.js';
import { createNamespacedHelpers } from 'vuex';
import types from './types';

export default {
  name: 'c-table',

  components: {
    CellHeader,
    Row,
    Cell,
    Paginatorv2,
  },

  props: {
    // классы
    containerClass: String,
    containerFilterClass: String,
    containerTableClass: String,
    tableClass: String,
    tableBodyClass: String,
    rowClass: String,
    columnClass: String,

    // Объект настроек для запроса на ДАННЫЕ для фильтра по колонке
    settingRequestFilter: {
      type: Object,
      default: () => {
        return {
          url: '', // урл запроса на конкретный фильтр
          payload: {}, // дополнительные ключи которые нужно вставить в запрос
        };
      },
    },

    // Нужно ли скролить таблицу или нет (при изменении)
    isScroll: {
      type: Boolean,
      default: true,
    },

    // всего страниц
    totalCount: {
      type: Number,
      default: 0,
    },

    // всего страниц
    totalPages: {
      type: Number,
      default: 0,
    },

    // объекты строк
    rows: {
      type: [Array, Object],
      default: () => [],
    },

    // настройки табличных фильтров
    filters: {
      type: Array,
      default: () => [],
    },

    // флаг скрытия таблицы
    hideTable: {
      type: Boolean,
      default: false,
    },

    // функция (экшен) прогрузки страницы
    loadTable: {
      type: Function,
      default: () => ({}),
    },

    // список столбцов которые можно скрыть
    columnListForHide: {
      type: Array,
      default: () => [],
    },

    // Плейсхолдер для инпута
    // tablePlaceholder: {
    //   type: String,
    //   default() {
    //     return `${this.$trans.templates.search} Product name, SKU, RPC ${this.$trans.templates.or} EAN/GTIN`;
    //   },
    // },
  },

  data() {
    return {
      cols: [], // колонки

      // функция темплейт графика, которую мы прокидываем внутрь другого компонента
      column: {
        template: null,
      },

      unselectedColumns: [],

      globalStore: {},
      observer: null, // обработчик отслеживания
      countEditingSlot: 0, // счетчик изменений содержимого слота

      widthForChart: 0, // ширина контейнера выпадающего графика
    };
  },

  computed: {
    name() {
      return this._uid + '_c-table';
    },

    // Корректный объект настроек для сортировки
    // Из строки "-brand" получаем {name: "brand", position: "down"}
    settingForSort() {
      return this.getSortingOptions(this[types.g.GET_SORT].inner);
    },

    nameLinkStorage() {
      return this[types.g.GET_NAME];
    },

    currentPage() {
      return this[types.g.GET_PAGE];
    },

    chartList() {
      return this[types.g.GET_CHARTS];
    },

    searchRow() {
      return this[types.g.GET_SEARCH];
    },

    filtersTableStore() {
      return this[types.g.GET_FILTER];
    },

    isLoading() {
      return this.store.loading;
    },

    listColumnsForFilter() {
      // отсекаем колонки для фильтрации, которые отключили
      return this.filters.filter(el => !this.unselectedColumns.includes(el.name));
    },
  },

  watch: {
    rows: {
      immediate: true,
      handler(items) {
        // Обнуление графиков
        this.refreshCharts(items);

        // если есть строки
        if (items.length) {
          this.$nextTick(() => {
            this.getComponentsInSlot();

            this.$nextTick(() => {
              this.setSizeTable();
              this.scrollingTop();
            });
          });
        } else {
          // если нет строк
          this.$nextTick(() => {
            this.setSizeTable();
          });
        }
      },
    },

    // если изменяется содержимое слота
    countEditingSlot() {
      if (!this.rows.length) return;

      // Обнуление графиков
      this.refreshCharts(this.rows);

      // просчитываем новые настройки прокинутых компонентов в slot
      this.getComponentsInSlot();

      // расчитываем ширину и прочее
      this.$nextTick(() => {
        this.setSizeTable();
      });
    },

    chartList(val) {
      // определяем ширину графиков
      const tw = this.$el.querySelector('.table__wrapper');
      this.widthForChart = tw.clientWidth - 3;
    },
  },

  beforeCreate() {
    this.$options.methods.createModule.call(this);
  },

  created() {
    window.addEventListener('resize', this.setSizeTable);
  },

  mounted() {
    // генерируем уникальную ссылку чтобы связать таблицу и локал стор
    this.generateLinkLocalStorage();
    this.watchingSlotContent();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setSizeTable);
    this.observer.disconnect();
    this.constructor.options.store.unregisterModule(['c-table-v3', this._uid]);
  },

  methods: {
    // создаем отдульный модуль для компонента
    // примешиваем мутации геттеры и экшены в компонент
    createModule() {
      const path = ['c-table-v3', this._uid];
      const module = localStoreTable();
      const store = this.constructor.options.store;

      // Если уже есть такой модуль - то удаляем
      if (store.state['c-table-v3'][this._uid]) {
        console.warn('c-table-v3: A module with the same name has been removed!');
        store.unregisterModule(path);
      }

      // Для каждой таблицы регистрируем свой подмодуль в модуле c-table-v3
      store.registerModule(path, module);
      // добавляем в компонент экшены мутации и геттерсы
      const { mapState, mapActions, mapMutations, mapGetters } = createNamespacedHelpers(path.join('/'));

      this.$options.computed = {
        ...(this.$options.computed || {}),
        ...mapGetters(Object.keys(types.g)),
        ...mapState({ store: state => state }),
      };

      this.$options.methods = {
        ...(this.$options.methods || {}),
        ...mapActions(Object.keys(types.a)),
        ...mapMutations(Object.keys(types.m)),
      };
    },

    // Экспререментальный декоратор - написанный на будущее
    // Когда таблицы и графики будут отдельными запросами и будет
    // точное определение когда и как блокировать страницу лоадером
    async get_data() {
      this[types.m.SET]({ key: 'loading', data: true });
      await this.loadTable();
      this[types.m.SET]({ key: 'loading', data: false });
    },

    // отслеживаем что содержимое slot изменилось
    watchingSlotContent() {
      // создаем обсерв с колбеком
      this.observer = new MutationObserver(function (mutations) {
        this.countEditingSlot++;
      }.bind(this));

      // запускаем обсерв на отслеживаение с настройками
      this.observer.observe(
        this.$el.querySelector('.js-fantoms'),
        { attributes: true, childList: true, characterData: true, subtree: true }
      );
    },

    getComponentsInSlot() {
      // Рекурсивно отсматриваем правильные компоненты
      // Ибо можно кинуть не <c-table-column> компонент,
      // а обертку, и тогда будет ошибка.
      // Нам нужно найти компонент в обертке
      function findColumnComponent(list) {
        const res = [];

        list.forEach((column, index, ll) => {
          if (column.tag) {
            if (column.tag.indexOf('c-table-column') > -1) {
              res.push(column);
            } else {
              const children = column.componentInstance.$children.map(el => el.$vnode);
              res.push(...findColumnComponent(children || []));
            }
          }
        });

        return res;
      }

      const columnComponents = findColumnComponent(this.$slots.default)
        .map(column => column.componentInstance);

      // если есть слот с графиком, то его забиваем в отдельную переменную и прокидываем в другой компонент
      if (this.$slots?.hashCharts?.[0]) {
        this.column.template = this.$slots.hashCharts[0].data.scopedSlots.default;
      }

      // делаем основной набор настроек по каждой строке
      this.cols = this.getListProps(columnComponents);

      // отключаем колонки при перерисовки (которые отключены)
      this.cols.forEach(obj => {
        const flag = this.unselectedColumns.includes(obj.name);

        if (flag) obj.show = false;
      });

      columnComponents.forEach(ColumnCom => {
        Object.keys(ColumnCom.$options.propsData).forEach(key => {
          // делаем вотчеры на отслеживание пропсов
          ColumnCom.$watch(key, () => {
            this.cols = this.getListProps(columnComponents);
          });
        });
      });
    },

    scrollingTop() {
      if (this.isScroll) {
        this.$el.querySelector('.table__wrapper').scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },

    paintSortingArrow(colName, val) {
      // сбрасываем сортировку везде
      const { position: new_position } = this.getSortingOptions(val);
      this.cols.forEach(el => { el.sorting = ''; });

      const col = this.cols.find(el => el.name === colName);
      col.sorting = new_position;
    },

    // получаем будущее положение сортировки
    futureResSort(colName, i) {
      let res = colName;
      const now_position = this.cols[i].sorting;

      switch (now_position) {
      case 'down':
        res = '';
        break;

      case 'up':
        res = `-${res}`;
        break;

      default:
        break;
      }

      return res;
    },

    // Определяем высоту и ширину таблицы (без этого часть функций великого css не работает)
    setSizeTable() {
      const table = this.$el.querySelector('table.table__table');

      // мини функция
      function getNumberValue(elem, attr) {
        return Math.ceil(parseFloat(window.getComputedStyle(elem)[attr]));
      }

      if (table) {
        table.style.height = '';
        table.style.width = '';

        table.style.width = getNumberValue(table, 'width') + 'px';
        table.style.height = getNumberValue(table, 'height') + 'px';
      }
    },

    // получаем корректный объект с настройками сортировки
    getSortingOptions(val) {
      let name = val;
      let position = '';

      if (val.length) {
        if (val[0] === '-') {
          name = val.slice(1);
          position = 'down';
        } else {
          position = 'up';
        }
      }

      return {
        name,
        position,
      };
    },

    // считаем left отступ для колонки
    // нужно для фиксирования столбцов
    getLeftValue(list, index) {
      const buffArr = list.slice(0, index).filter(obj => obj.show);
      let res = 0;

      buffArr.forEach(el => {
        res += parseInt(el.width);
      });

      return res + 'px';
    },

    // делаем набор настроек по каждой строке
    getListProps(list) {
      return list.map((obj, index) => {
        const setting = this.settingForSort;
        const position = setting.name === obj.name ? setting.position : '';

        return {
          label: obj.label,
          name: obj.name,
          customClass: obj.customClass,
          width: obj.width || '200px',
          show: obj.show,
          fixed: obj.fixed,
          // считаем позицию столбца (для position sticky)
          left: obj.fixed ? this.getLeftValue(list, index) : null,
          // отображенеи сортировки
          sort: obj.sort,
          // позиция сортировки
          sorting: position,
          filter: obj.filter,
          template: obj.$scopedSlots.default,
          style: obj.$vnode.data.style || {},
        };
      });
    },

    // Очищаем массив с графиками
    refreshCharts(list) {
      const charts = list.map(row => ({
        isOpen: false,
        chart: {},
        height: {},
      }));

      this[types.m.SET]({ key: 'charts', data: charts });
    },

    // генерируем ссылку для связи между таблицей и локал стором
    generateLinkLocalStorage() {
      const route = this.$route.name;
      // смотрим в сторе все таблицы в c-table3
      const tables = this.constructor.options.store.state['c-table-v3'];

      // собираем их нейминги
      const list = Object.keys(tables).map(key => ({
        key,
        name: tables[key].name,
      })).filter(el => el.name.includes(route));

      this[types.m.SET]({ key: 'name', data: route + '#' + list.length });
    },

    // ====================== EVENTS ======================
    // Евент пагинации таблицы
    changePage(page) {
      this.scrollingTop();
      this.$emit('paginate', page);
    },

    // Евент сортировки
    changeSort(name, index) {
      const valueForFilter = this.futureResSort(name, index);

      // Красим сортировку
      this.paintSortingArrow(name, valueForFilter);

      this.$emit('sort', valueForFilter);
    },

    // Евент применения таблиных фильтров
    submit(obj) {
      this.$emit('submit', obj);
    },

    reset(obj) {
      const res = { key: `reset-${obj}`, val: {} };
      this.$emit('reset-single-filter', res);
    },

    // Евент отображения столбцов
    changeColumn({ list, mutate }) {
      this.unselectedColumns = list;

      if (mutate) {
        this.$emit('change-columns', this.unselectedColumns);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrap
  height: 100%
  position: relative
  border-left: 1px solid $blue-grey-100
  border-right: 1px solid $blue-grey-100
  border-top: 1px solid $blue-grey-100
  width:100%
  &__loader
    display: flex
    position: absolute
    justify-content: center
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 8
    align-items: center
    i
      color: $blue-grey-200
  &__main-wrap
    display: flex
    flex-direction: column
    height: 100%
  &__container
    position: relative
    overflow: hidden
    flex-grow: 1

.table
  &__wrapper
    height: 100%
    overflow scroll
  &__table
    width: 100%
    table-layout fixed
    border-collapse: collapse
  &__header-row
    background: $grey-50
    white-space: nowrap
    left: 0
  &__buffer-th
    position: sticky
    top: 0
    background: $grey-50
    box-shadow: inset 0px -1px 0px 0px $lightgrey
    height: 49px
    z-index: 3
  &__row-hash
    border-bottom: 0.1rem solid $lightgrey
  &__row-sticky
    position: sticky
    left: 1px

.warning
  position: absolute
  top: 55px
  width: calc(100% - 10px)
  margin-left: 5px
  padding: 15px 0
  font-size: 13px
  text-align: center
  color: darken($orange-500, 50%);
  background-color: lighten($orange-500, 85%);
  border-left: 4px solid $orange-500
  .wrap_nothing
    display:flex
    justify-content:center
    align-items: center

label
  margin: 0

.footer
  height: 40px !important
  flex-basis: 40px !important
  &_goods
    font-size: 12px
    white-space: nowrap
  &_slot
    display: flex
    width: 100%
    align-items: center
  &_paginator
    margin-right: 5px
  &_pages
    white-space: nowrap
</style>