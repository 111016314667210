<template>
  <div
    class="modal">
    <div class="wrapper">
      <i
        :style="{ opacity: previousElement ? 1 : 0 }"
        class="fa-solid fa-chevron-left"
        @click="swipeLeft" />

      <div
        class="wrapper_modal">
        <header class="header">
          <div class="title">
            {{ $t('compProducts') }}
          </div>
          <skeleton
            v-if="modalCompare.isLoading"
            width="14rem" />
          <rating-box
            v-else
            :match="modalCompare.statusLink.match" />
          <div class="right">
            <skeleton
              v-if="modalCompare.isLoading"
              width="1rem" />
            <div
              v-else
              class="close-modal"
              @click="closeModal">
              <i class="pi pi-times" />
            </div>
          </div>
        </header>

        <div
          class="cols">
          <div class="col">
            <skeleton
              v-if="modalCompare.isLoading"
              width="510px"
              height="100%"
              class="skeleton_item" />
            <item-card
              v-else
              :item="store" />
          </div>
          <div class="col">
            <skeleton
              v-if="modalCompare.isLoading"
              width="510px"
              height="100%"
              class="skeleton_item" />
            <item-card
              v-else
              :item="candidate" />
          </div>
        </div>

        <footer
          class="footer">
          <skeleton
            v-if="modalCompare.isLoading"
            width="5rem" />
          <template v-else>
            <ask
              v-if="filters.type === 'rpc2sku' || filters.type === 'sku2rpc'"
              class="mr10"
              :current-item-id="modalCompare.statusLink.id"
              :distance="modalCompare.statusLink.distance"
              :distance-pct="modalCompare.statusLink.match"
              :position="modalCompare.statusLink.position"
              :is-approved="modalCompare.statusLink.is_approved"
              :key-filters="modalCompare.statusLink.keyFilters"
              :is-linked="modalCompare.statusLink.is_linked"
              @closeModal="closeModal" />

            <linker-switch
              v-if="filters.type!==`linksManager`"
              :status="modalCompare.statusLink.linked"
              :current-item-id="modalCompare.statusLink.id"
              :type="modalCompare.statusLink.type"
              :distance="modalCompare.statusLink.distance"
              :distance-pct="modalCompare.statusLink.match"
              :position="modalCompare.statusLink.position"
              :is-approved="modalCompare.statusLink.is_approved"
              :is-linked="modalCompare.statusLink.is_linked"
              :key-filters="modalCompare.statusLink.keyFilters"
              @closeModal="closeModal" />

            <status-action-candidates
              v-else
              :list="[`${modalCompare.statusLink.pim_item_id}_${modalCompare.statusLink.store_item_id}`]"
              :status="modalCompare.statusLink.status" />
          </template>
        </footer>
      </div>

      <i
        :style="{opacity: nextElement ? 1 : 0}"
        class="fa-solid fa-chevron-right"
        @click="swipeRight" />
    </div>
  </div>
</template>

<script>
import { RPC2SKU } from '@/js/constants';
import ItemCard from '@/components/Modal/ItemCard';
import RatingBox from '@/assets/RatingBox';
import Skeleton from 'primevue/skeleton';
import LinkerSwitch from '@/assets/LinkerSwitch';
import Ask from '@/assets/Ask';
import { mapState, mapMutations } from 'vuex';
import StatusActionCandidates from '@/assets/StatusActionCandidates';

export default {
  name: 'ModalCompare',

  components: {
    ItemCard,
    RatingBox,
    Skeleton,
    LinkerSwitch,
    StatusActionCandidates,
    Ask,
  },

  data() {
    return {
      linkChecked: false,
      RPC2SKU,
    };
  },

  computed: {
    ...mapState({
      filters: state => state.filters,
      modalCompare: state => state.modalCompare,
    }),

    store() {
      let res = this.modalCompare.pimInfo;

      if (this.filters.type === RPC2SKU) {
        res = this.modalCompare.info;
      }

      return res;
    },

    candidate() {
      let res = this.modalCompare.info;

      if (this.filters.type === RPC2SKU) {
        res = this.modalCompare.pimInfo;
      }

      return res;
    },

    previousElement() {
      const element = document.querySelector(`[data-goods="${this.modalCompare.statusLink.type}_${this.modalCompare.statusLink.id}"]`);

      return element ? element.previousElementSibling : null;
    },

    nextElement() {
      const element = document.querySelector(`[data-goods="${this.modalCompare.statusLink.type}_${this.modalCompare.statusLink.id}"]`);

      return element ? element.nextElementSibling : null;
    },
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    closeModal() {
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isOpen',
        data: false,
      });
    },

    swipeLeft() {
      // this.closeModal();
      this.previousElement.click();
    },

    swipeRight() {
      // this.closeModal();
      this.nextElement.click();
    },
  },
};
</script>

<style lang="stylus" scoped>
.modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  background: rgba(0, 0, 0, 0.5)
  padding: 50px
  z-index: 9

.wrapper
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  height: 100%

.fa-chevron-right,.fa-chevron-left
  color: white
  font-size: 30px
  padding: 10px
  cursor: pointer

.mr10
  margin-right: 10px

.wrapper_modal
  display: flex
  flex-direction: column
  position: relative
  z-index: 3
  padding: 12px
  border-radius: 4px
  background: $white
  width: 100%
  max-width: 1100px
  height: 100%
  min-height: 500px
  overflow: auto

.header
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 16px

.title
  font-weight: 300
  font-size: 20px
  line-height: 24px
  color: $blue-grey-700
  width: 200px

.right
  display: flex
  flex-direction: row-reverse
  width: 200px

.cols
  display: flex
  justify-content: space-between
  align-content: stretch
  flex: 1 0 0

.col
  box-sizing: border-box
  width: 49%
  padding: 8px
  background: $grey-50

.skeleton_item
  margin-right: 16px

.footer
  display: flex
  justify-content: flex-end
  padding-top: 16px
</style>