<template>
  <div class="approval_page">
    <approval-filters />
    <approval-table v-if="filters.account && stores.length && brands.length" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApprovalFilters from './ApprovalFilters';
import ApprovalTable from './ApprovalTable';

export default {
  name: 'Approval',

  components: {
    ApprovalFilters,
    ApprovalTable,
  },

  computed: {
    ...mapState([
      'stores',
      'brands',
      'filters',
    ]),
  },
};
</script>

<style lang="stylus" scoped>
.approval_page
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  min-height: 0;
</style>