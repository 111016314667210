<template>
  <div class="photo">
    <div class="photo-main">
      <image-box
        :key="mainImageKey"
        class="slide-img"
        :image="mainImage"
        type="original"
        @clicked="showMain" />
    </div>
    <div class="carousel">
      <hooper
        v-if="images.length > 1"
        :items-to-show="3"
        pagination="no"
        style="height: 32px"
        :trim-white-space="true"
        :mouse-drag="false"
        :touch-drag="false"
        :wheel-control="false"
        :keys-control="false"
        :short-drag="false">
        <slide
          v-for="(image, index) in images"
          :key="`sm_${index}`"
          :index="index">
          <div class="slide-wrapper">
            <image-box
              class="slide-img"
              :image="image"
              @clicked="setCurrentSlide" />
          </div>
        </slide>
        <hooper-navigation
          v-if="images.length > 3"
          slot="hooper-addons" />
      </hooper>
    </div>
  </div>
</template>

<script>
import ImageBox from '@/assets/ImageBox';
import { mapMutations } from 'vuex';
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'GoodsGallery',

  components: {
    ImageBox,
    Hooper,
    Slide,
    HooperNavigation,
  },

  props: {
    images: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      mainImage: this.images[0],
      mainImageKey: 0,
      mainImgLarge: null,
    };
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    showMain({
      _,
      src,
    }) {
      this.M_MUTATE_BY_KEY({
        key: 'mainImgLarge',
        data: src,
      });
    },

    handleHide() {
      this.mainImgLarge = null;
    },

    setCurrentSlide(data) {
      this.mainImage = data.image;
      this.mainImageKey++;
    },
  },
};
</script>

<style lang="stylus" scoped>
.photo
  width: 120px

.photo-main
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  height: 130px
  background: $white
  border: 1px solid $blue-grey-100
  border-radius: 4px
  margin-bottom: 8px

::v-deep .previewImg
  max-width: 100%
  max-height: 100%

.disabled
  cursor: default
  pointer-events: none

.slide-img
  position: relative
  z-index: 3
  max-width: 100%
  max-height: 100%

.slide-wrapper
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  width: 30px
  height: 32px
  background: $white

::v-deep .hooper-next, ::v-deep .hooper-prev
  padding: 0

::v-deep .hooper-next
  right: -20px

::v-deep .hooper-prev
  left: -20px

.carousel
  position: relative
  z-index: 3
  padding: 0 14px
</style>