function defaultFiltersRpc2Sku(account) {
  return {
    account: account,
    type: 'rpc2sku',
    stores: [],
    brands: [],
    suppliers_type: 'all',
    only_linked: null,
    global_term: '',
    sub_term: '',
    candidate_sub_term: '',
    created_date_from: undefined,
    created_date_to: undefined,
    actual_date_from: undefined,
    actual_date_to: undefined,
    distance: [70, 100],
    selectedId: null,
    page: 1,
    candidate_search_algorithm: 'name',
  };
}

//  Подготовка query для router push
function prepareToUrl(query) {
  const copyQuery = {};

  for (const key in query) {
    if (query[key]) {
      copyQuery[key] = query[key];
    }
  }

  // Фильтры с множественным выбором
  copyQuery.brands = JSON.stringify(copyQuery.brands);
  copyQuery.stores = JSON.stringify(copyQuery.stores);
  copyQuery.issuer = JSON.stringify(copyQuery.issuer);

  return copyQuery;
}

//  Подготовка для заполнения в стор
function prepareToStore(object) {
  if ('brands' in object && object.brands) {
    // Если это json то парсим
    if (typeof object.brands === 'string') {
      object.brands = JSON.parse(object.brands);
    }
  }
  if ('stores' in object && object.stores) {
    let stores = object.stores;
    // Если это json то парсим
    if (typeof object.stores === 'string') {
      stores = JSON.parse(object.stores);
    }

    object.stores = Array.isArray(stores) ? stores.map((item) => +item) : [];
  }
  if ('issuer' in object && object.issuer) {
    // Если это json то парсим
    if (typeof object.issuer === 'string') {
      object.issuer = JSON.parse(object.issuer);
    }
  }
  if ('distance' in object) {
    object.distance = [+object.distance[0], +object.distance[1]];
  }
  if ('only_linked' in object && typeof object.only_linked === 'string') {
    object.only_linked = JSON.parse(object.only_linked);
  }
  if ('page' in object) {
    object.page = +object.page;
  }
  if ('selectedId' in object && object.selectedId) {
    object.selectedId = +object.selectedId;
  }
  if ('position' in object) {
    object.position = +object.position;
  }

  return object;
}

function dateToFormat(date) {
  let yourDate = new Date(date);
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));

  return yourDate.toISOString().split('T')[0];
}

// Сокращаем большие числа 1000=1k итд
function tarnsformCount(count) {
  if (!count) {
    return 0;
  }

  let n = 0;

  while (count > 1000) {
    count /= 1000;
    n++;
  }

  return +count.toFixed(1) + (['', 'k', 'm', 'b', 't'][n] || '');
}

function cropUrl(str = '') {
  let res = str;

  res = res.replace('https://', '');
  res = res.replace('http://', '');

  return res;
}

export default {
  prepareToUrl,
  prepareToStore,
  dateToFormat,
  defaultFiltersRpc2Sku,
  tarnsformCount,
  cropUrl,
};