import { vars } from './additions';

export default function request(opt = {}, isRecursion = false) {
  if ('body' in opt) opt.body = JSON.stringify(opt.body);

  if ('params' in opt) {
    opt.url = opt.url + '?' + new URLSearchParams(opt.params);
  }

  const { url, ...config } = opt;

  return fetch(vars.linker_host + '/api/internal/' + opt.url, {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      jwt: vars.sso_token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject(response);
      }
    })
    .catch(async (error) => {
      if (vars.integration) {
        if (error.status === 401 && !isRecursion) {
          const res = await request({
            method: 'get',
            url: 'refresh_token/',
          });

          vars.sso_token = res.sso_token;

          await request(opt, true);
        } else if (error.status === 403) {
          document.location.href = '/error/403/';
        }
      } else {
        if (error.status === 401 || error.status === 403) {
          document.location.href = vars.linker_host + '/api/internal/forbidden/';
        }
      }

      throw new Error(error);
    });
}
