<template>
  <main class="content">
    <div
      class="app-main cols">
      <c-table-v3
        ref="table"
        :rows="rowsWithLoader"
        :total-count="table.count"
        :total-pages="totalPages"
        @paginate="eventPaginate"
        @sort="eventSort">
        <div
          slot="content-buffer">
          <checkbox
            :value="$data.$selectAll"
            :is-three-dot="!!$data.$selectedId.length"
            @click.native="$chooseAll()" />
        </div>

        <c-table-column-v3
          fixed
          width="40px"
          name="buffer"
          :sort="false"
          :custom-class="'buffer_column'">
          <template slot-scope="{ pim_item_id, store_item_id }">
            <div>
              <checkbox
                :value="$activityValue(`${pim_item_id}_${store_item_id}`)"
                @click.native="$changeChoice(`${pim_item_id}_${store_item_id}`, table.count)" />
            </div>
          </template>
        </c-table-column-v3>

        <c-table-column-v3
          fixed
          width="190px"
          name="status"
          :sort="true"
          :label="$t('agreementStatus')"
          :custom-class="'buffer_column'">
          <template slot-scope="{ status, pim_item_id, store_item_id }">
            <status-action-candidates
              :list="[`${pim_item_id}_${store_item_id}`]"
              :status="status" />
          </template>
        </c-table-column-v3>

        <c-table-column-v3
          width="350px"
          name="StoreInfo"
          :sort="false"
          :label="$t('storeItem')">
          <template slot-scope="row">
            <product-info
              :obj="{
                title: row.title,
                rpc: row.rpc,
                url: row.url,
                image_preview: row.cache_image.preview || row.image,
                image_table: row.cache_image.table || row.image,
                brand: row.brand,
              }"
              @showModalCompare="showModalCompare(row)" />
          </template>
        </c-table-column-v3>

        <c-table-column-v3
          width="350px"
          name="AccountInfo"
          :sort="false"
          :label="$t('accountItem')">
          <template slot-scope="row">
            <product-info
              :obj="{
                title:row.pim_title,
                sku:row.sku,
                url:row.pim_link,
                image_preview: row.pim_image,
                image_table: row.pim_image,
                brand:row.pim_brand,
              }"
              @showModalCompare="showModalCompare(row)" />
          </template>
        </c-table-column-v3>

        <c-table-column-v3
          :label="$t('store')"
          :sort="false"
          name="store" />

        <c-table-column-v3
          :label="$t('dateCreated')"
          name="created_at">
          <template slot-scope="{ created_at }">
            <span>{{ created_at.split(' ')[0] }}</span>
          </template>
        </c-table-column-v3>

        <c-table-column-v3
          :label="$t('author')"
          name="issuer" />

        <c-table-column-v3
          :label="$t('searchAlgorithm')"
          width="250px"
          name="search_algorithm">
          <template slot-scope="{ search_algorithm }">
            <span>{{ search_algorithm.toUpperCase() }}</span>
          </template>
        </c-table-column-v3>

        <c-table-column-v3
          :label="$t('percentMatch')"
          name="distance_pct" />

        <c-table-column-v3
          :label="$t('rankPosition')"
          name="position" />

        <c-table-column-v3
          :label="$t('dateModified')"
          name="reviewed_at"
          :sort="false">
          <template slot-scope="{ reviewed_at }">
            <span>{{ reviewed_at.split(' ')[0] }}</span>
          </template>
        </c-table-column-v3>

        <c-table-column-v3
          :label="$t('authorChanges')"
          name="reviewer"
          :sort="false" />
      </c-table-v3>
    </div>
  </main>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { SKU2RPC, RPC2SKU } from '@/js/constants';
import mixinChooseAll from '@/mixins/mixinChooseAll';
import Skeleton from 'primevue/skeleton';
import ProductInfo from '@/assets/ProductInfo';
import StatusActionCandidates from '@/assets/StatusActionCandidates';

export default {
  name: 'ApprovalTable',

  components: {
    Skeleton,
    ProductInfo,
    StatusActionCandidates,
  },

  mixins: [
    mixinChooseAll,
  ],

  data() {
    return {
      TAB: {},
      rowsWithLoader: [],
    };
  },

  computed: {
    ...mapState([
      'stores',
      'brands',
      'filters',
      'updateTable',
      'reloadTable',
    ]),

    ...mapState({
      rows: state => state.approval_table.table_details.results,
      table: state => state.approval_table.table_details,
      loadingRowsId: state => state.approval_table.loadingRowsId,
    }),

    selectAll() {
      return this.$data.$selectAll;
    },

    selectItemsIds() {
      return this.$data.$selectedId;
    },

    totalPages() {
      let res = 0;

      if (this.rows.length) {
        res = Math.ceil(this.table.count / this.table.page_size);
      }

      return res;
    },
  },

  watch: {
    // При апруве или денайде (может быть в таблице/ в масовых операция/в модалке) надо обновить таблицу
    updateTable: {
      handler() {
        this.loadPage();
      },
    },

    reloadTable: {
      handler() {
        this.$resetSelected();
        this.$refs.table.CLEAR_STATE();
        this.loadPage();
      },
    },

    selectAll: {
      handler(val) {
        this.M_MUTATE_BY_KEY({
          key: 'modalBulkOperation.select_all',
          data: val,
        });

        this.M_MUTATE_BY_KEY({
          key: 'modalBulkOperation.count',
          data: this.countSelectedProducts(),
        });
      },
    },

    selectItemsIds: {
      handler(val) {
        this.M_MUTATE_BY_KEY({
          key: 'modalBulkOperation.select_items',
          data: val,
        });
        this.M_MUTATE_BY_KEY({
          key: 'modalBulkOperation.count',
          data: this.countSelectedProducts(),
        });
      },
    },

    rows: {
      immediate: true,
      handler(val) {
        this.rowsWithLoader = val.slice();
      },
    },

    loadingRowsId: {
      immediate: true,
      handler(val) {
        this.rowsWithLoader = this.rowsWithLoader.map((item) => {
          item.isloading = val.includes(`${item.pim_item_id}_${item.store_item_id}`);

          return item;
        });
      },
    },
  },

  mounted() {
    this.TAB = this.$refs.table.store;
  },

  methods: {
    ...mapActions([
      'A_GET_TABLE_APPROVAL',
      'GET_GOOD_INFO',
      'GET_GOOD_INFO_PIM',
    ]),

    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    countSelectedProducts() {
      let res = 0;

      if (this.$data.$selectAll) {
        res = this.table.count - this.$data.$selectedId.length;
      } else {
        res = this.$data.$selectedId.length;
      }

      return res;
    },

    async loadPage() {
      this.$refs.table.SET({ key: 'loading', data: true });
      await this.A_GET_TABLE_APPROVAL(this.TAB);
      this.$refs.table.SET({ key: 'loading', data: false });
    },

    async showModalCompare(row) {
      await this.GET_GOOD_INFO_PIM({ id: row.pim_item_id, type: SKU2RPC });
      await this.GET_GOOD_INFO({ id: row.store_item_id, type: RPC2SKU });

      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.statusLink',
        data: {
          match: row.distance_pct,
          status: row.status,
          pim_item_id: row.pim_item_id,
          store_item_id: row.store_item_id,
        },
      });

      // данные обновились можно показывать модалку
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isOpen',
        data: true,
      });
    },

    // =================== Эвенты для таблицы ===================
    eventPaginate(val) {
      this.$refs.table.SET_PAGE(val);
      this.loadPage();
    },

    eventSort(val) {
      this.$refs.table.SET_FILTERS({ key: 'sort', val });
      this.loadPage();
    },

    eventResetSingleFilter(val) {
      this.$refs.table.SET_FILTERS(val);
      this.loadPage();
    },
  },
};
</script>

<style lang="stylus" scoped>
.content
  flex: 1 0 0
  min-height: 0
  padding: 0 16px

.cols
  display: flex
  gap: 32px
  height: 100%
  overflow: auto

.p-skeleton
  height: 100% !important
</style>