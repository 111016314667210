<template>
  <dropdown-a-l
    :title="`${data.placeholder} (${filters[data.storeName].join(', ')})`">
    <div class="slider-wrapper">
      <div class="slider-start">
        {{ localFilters[data.storeName][0] }}
      </div>
      <div class="slider-line">
        <slider
          v-model="localFilters[data.storeName]"
          :range="data.range"
          @change="$emit('applySlider')" />
      </div>
      <div class="slider-end">
        {{ localFilters[data.storeName][1] }}
      </div>
    </div>
  </dropdown-a-l>
</template>

<script>
import DropdownAL from '@/assets/AL-Dropdown';
import Slider from 'primevue/slider';
import { mapState } from 'vuex';

export default {
  name: 'sliderFilter',

  components: {
    DropdownAL,
    Slider,
  },

  props: {
    // Основные данные
    data: {
      type: Object,
      default: () => ({}),
    },

    // Локальный фильтр
    localFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState([
      'filters',
    ]),
  },
};
</script>

<style lang="stylus" scoped>
.slider-wrapper
  display: flex
  align-items: center
  .slider-line
    flex: 1 0 0
    padding: 0 20px
  .slider-start,
  .slider-end
    width: 40px
    height: 35px
    text-align: center
    line-height: 35px
    font-size: 13px
    background: $grey-100
    border-radius: 4px
</style>