<template>
  <div class="row">
    <div
      :class="['status',{
        approved: status==='Approved',
        denied: status==='Denied',
        waiting: status==='Waiting',
        processing: status==='Processing',
      }]">
      {{ textStatus }}
    </div>

    <button
      class="action_button"
      :disabled="disabled"
      @click="sendApprove">
      <i class="far fa-circle-check" />
    </button>

    <button
      class="action_button"
      :disabled="disabled"
      @click="sendDenied">
      <i class="far fa-circle-xmark" />
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'StatusActionCandidates',

  props: {
    status: {
      type: String,
      default: '',
    },

    // масив выбранных связей формата ${pim_item_id}_${store_item_id}
    list: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      disabled: false,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.userProfile.permissions,
      loadingRowsId: state => state.approval_table.loadingRowsId,
    }),

    isCanManageLinks() {
      let res = false;
      if ('can_manage_links' in this.permissions) {
        res = this.permissions.can_manage_links;
      }

      return res;
    },

    textStatus() {
      let res = '';
      if (this.status === 'Approved') {
        res = this.$t('approved');
      } else if (this.status === 'Denied') {
        res = this.$t('denied');
      } else if (this.status === 'Waiting') {
        res = this.$t('waiting');
      } else if (this.status === 'Processing') {
        res = this.$t('processing');
      }

      return res;
    },
  },

  watch: {
    isCanManageLinks: {
      immediate: true,
      handler(val) {
        this.disabled = !val;
      },
    },
  },

  methods: {
    ...mapActions([
      'A_SEND_APPROVE',
      'A_SEND_DENIED',
    ]),

    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    async sendApprove() {
      this.disabled = true;

      // Делаем лоудер на строку
      const allRows = this.loadingRowsId.concat(this.list);

      this.M_MUTATE_BY_KEY({
        key: 'approval_table.loadingRowsId',
        data: allRows,
      });

      await this.A_SEND_APPROVE({
        items: this.list,
        table: 'approval_table',
      });

      // Закрываем модалку сравнения
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isOpen',
        data: false,
      });

      this.disabled = false;
    },

    async sendDenied() {
      this.disabled = true;

      // Делаем лоудер на строку
      const allRows = this.loadingRowsId.concat(this.list);

      this.M_MUTATE_BY_KEY({
        key: 'approval_table.loadingRowsId',
        data: allRows,
      });

      await this.A_SEND_DENIED({
        items: this.list,
        table: 'approval_table',
      });

      // Закрываем модалку сравнения
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isOpen',
        data: false,
      });

      this.disabled = false;
    },
  },

};
</script>

<style lang="stylus" scoped>
.row
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center

  .action_button
    display: flex;
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 6px
    width: 24px
    height: 24px
    background: $white
    border: 1px solid $blue-grey-100
    border-radius: 4px
    margin-left: 8px
    &:hover
      .fa-circle-check
        color: $green-300
      .fa-circle-xmark
        color: $red-300
    &:disabled
      .fa-circle-check
        color: $blue-grey-200
      .fa-circle-xmark
        color: $blue-grey-200

.status
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 4px 12px
  border-radius: 50px
  color: $blue-grey-700
  font-weight: 500
  min-width: 110px
  &.approved
    background-color: $green-50
  &.denied
    background-color: $red-50
  &.waiting
    background-color: $blue-grey-50
  &.processing
    background-color: $grey-50
</style>