<template>
  <div class="photo">
    <div class="photo-main">
      <div class="main-img">
        <vue-photo-zoom-pro
          :high-url="mainImgLarge"
          :out-zoomer="true"
          :disabled="mainImgLarge === NO_IMAGE">
          <image-box
            :key="mainImageKey"
            :image="mainImage"
            type="original"
            @setImage="setMainImageUrl" />
        </vue-photo-zoom-pro>
      </div>
    </div>
    <div
      v-if="images.length > 1"
      class="carousel">
      <hooper
        :items-to-show="3"
        :trim-white-space="true"
        pagination="no"
        style="height: 136px">
        <slide
          v-for="(container, index) in formatedImages"
          :key="`cn_${index}`"
          :index="index">
          <div class="slide-wrapper">
            <div
              v-for="(image, i) in container"
              :key="`bg_${i}`"
              class="image-box">
              <image-box
                :image="image"
                @clicked="setCurrentSlide" />
            </div>
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons" />
      </hooper>
    </div>
  </div>
</template>

<script>
import { NO_IMAGE } from '@/js/constants';

import vuePhotoZoomPro from 'vue-photo-zoom-pro';
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css';

import ImageBox from '@/assets/ImageBox';

import { Hooper, Navigation as HooperNavigation, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'Gallery',

  components: {
    ImageBox,
    Hooper,
    Slide,
    HooperNavigation,
    vuePhotoZoomPro,
  },

  props: {
    images: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      NO_IMAGE,
      mainImage: null,
      mainImageKey: 0,
      mainImgLarge: null,
    };
  },

  computed: {
    formatedImages() {
      return this.images.reduce((arr, current, index) => {
        if ((index + 1) % 2) {
          arr.push([current]);
        } else {
          arr[arr.length - 1].push(current);
        }

        return arr;
      }, []);
    },
  },

  created() {
    this.mainImage = this.images[0];
  },

  methods: {
    setMainImageUrl(src) {
      this.mainImgLarge = src;
    },

    setCurrentSlide(data) {
      this.mainImage = data.image;
      this.mainImageKey++;
    },
  },
};
</script>

<style lang="stylus" scoped>
.photo
  display: flex
  align-items: center
  justify-content: space-between
  min-width: 0

.photo-main
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  width: 240px
  height: 272px
  background: $white
  border: 1px solid $blue-grey-100
  border-radius: 4px
  min-width: 0
  transition: all 0.5s

.main-img
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  img
    display: block
    max-width: 100%
    max-height: 100%

::v-deep .vue-photo-zoom-pro
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

::v-deep .vue-photo-zoom-pro .zoom-region
  position: relative
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

::v-deep .zoomer.out-zoomer
  position: absolute
  top: calc(100% + 8px) !important
  left: 0 !important
  transform: translate(0)
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08)

.disabled
  cursor: default
  pointer-events: none

.carousel
  flex: 1 0 0
  box-sizing: border-box
  padding-left: 36px
  padding-right: 24px
  min-width: 0

.slide-img
  max-width: 100%
  max-height: 100%

.slide-wrapper
  display: flex
  flex-direction: column
  justify-content: space-between
  box-sizing: border-box
  width: 58px
  height: 136px

.image-box
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  width: 56px
  height: 64px
  background: $white
  border: 1px solid $blue-grey-100
  border-radius: 4px
  img
    cursor: pointer
    max-width: 100%
    max-height: 100%

::v-deep .hooper-next, ::v-deep .hooper-prev
  padding: 0

::v-deep .hooper-next
  right: -24px

::v-deep .hooper-prev
  left: -24px
</style>