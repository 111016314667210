<template>
  <div class="match-info">
    <div class="match-title">
      {{ $t('match') }}:
    </div>
    <div class="match-stars">
      <rating
        :cancel="false"
        :readonly="true"
        :value="convertedValue" />
    </div>
    <div class="match-value">
      ({{ checkedMatch }}%)
    </div>
  </div>
</template>

<script>
import Rating from 'primevue/rating';

export default {
  name: 'RatingBox',

  components: {
    Rating,
  },

  props: {
    match: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    convertedValue() {
      return Math.ceil((this.checkedMatch / 100) * 5);
    },

    checkedMatch() {
      return this.match > 0 ? this.match : 0;
    },
  },
};
</script>

<style lang="stylus" scoped>
.match-info
  display: flex
  gap: 8px
  align-items: center

::v-deep .p-rating .p-rating-icon
  font-size: 12px
</style>