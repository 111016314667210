var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods-group"},[_c('div',{staticClass:"items-top"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.itemsTitle)+" ")]),((_vm.type === 'candidatesPim' || _vm.type === 'candidatesStore') && !_vm.isCanOnlySee)?_c('div',{staticClass:"switchMethod"},[_c('i',{staticClass:"fa-solid fa-text-size"}),_c('input-switch',{on:{"change":_vm.changeSearchAlgorithm},model:{value:(_vm.isImageAlgorithm),callback:function ($$v) {_vm.isImageAlgorithm=$$v},expression:"isImageAlgorithm"}}),_c('i',{staticClass:"fa-solid fa-images"})],1):_vm._e(),((_vm.type === 'candidatesPim' || _vm.type === 'candidatesStore') && _vm.goods.results.length && !_vm.isCanOnlySee)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
        theme: 'light',
        delay: [500, 0],
        arrow: true,
        arrowType: 'round',
        animation: 'fade',
        content: this.$t('noCandidates')
      }),expression:"{\n        theme: 'light',\n        delay: [500, 0],\n        arrow: true,\n        arrowType: 'round',\n        animation: 'fade',\n        content: this.$t('noCandidates')\n      }"}],class:['btnNoCandidates', {disable: _vm.disableNoCandidates}],on:{"click":_vm.clickNoCandidates}},[_c('i',{staticClass:"far fa-comment-dots"})]):_vm._e(),(_vm.goods.pages)?_c('goods-pagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"fetchPage":function($event){return _vm.$emit('fetchPage', $event)}}}):_vm._e()],1),_c('goods-search',{attrs:{"type":_vm.type,"store-id":_vm.storeId,"disabled-search":_vm.disabledSearch,"value":_vm.filters[_vm.keyFilters + 'sub_term'] || ''},on:{"searchLocal":function($event){return _vm.$emit('searchLocal', $event)}}}),(_vm.goods.results && _vm.goods.results.length)?_c('div',{staticClass:"items"},_vm._l((_vm.goods.results),function(item){return _c('goods-item',{key:item.id,attrs:{"item":item,"type":_vm.type,"key-filters":_vm.keyFilters},on:{"setSelectedIdGoods":function($event){return _vm.$emit('setSelectedIdGoods', $event)},"getCompareItems":function($event){return _vm.$emit('getCompareItems', $event)}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }