var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.column.show)?_c('th',{class:[
    _vm.columnHeader.wrapper,
    ( _obj = {}, _obj[_vm.columnHeader.fixed] = _vm.column.fixed, _obj )
  ],style:({ left: _vm.column.left }),attrs:{"width":_vm.column.width}},[_c('div',{class:_vm.columnHeader.item},[_c('div',{class:_vm.columnHeader.content},[_c('span',{class:_vm.columnHeader.label},[_vm._v(" "+_vm._s(_vm.column.label)+" ")]),_vm._t('content-' + _vm.column.name)],2),_c('div',{class:_vm.columnHeader.operations},[(_vm.isSorting)?_c('div',{class:_vm.columnHeader.sort,on:{"click":_vm.sort}},[_c('i',{staticClass:"fas fa-sort-up",class:[
            _vm.columnHeader.sort__up,
            ( _obj$1 = {}, _obj$1[_vm.columnHeader.active] = _vm.column.sorting === 'up', _obj$1 )
          ]}),_c('i',{staticClass:"fas fa-sort-down",class:[
            _vm.columnHeader.sort__down,
            ( _obj$2 = {}, _obj$2[_vm.columnHeader.active] = _vm.column.sorting === 'down', _obj$2 )
          ]})]):_vm._e(),_vm._t("filter")],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }