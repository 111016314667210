<template>
  <div class="row">
    <div
      :class="['status', classColorStatus]">
      {{ textStatus }}
    </div>

    <button
      class="action_button"
      :disabled="disabledGlobal || disabledApprove"
      @click="sendApprove">
      <i class="far fa-circle-check" />
    </button>

    <button
      class="action_button"
      :disabled="disabledGlobal || disabledDisapprove"
      @click="sendDenied">
      <i class="far fa-circle-xmark" />
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'StatusActionCandidates',

  props: {
    status: {
      type: String,
      default: '',
    },

    // масив выбранных связей формата ${pim_item_id}_${store_item_id}
    list: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      disabledGlobal: false,
      disabledApprove: false,
      disabledDisapprove: false,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.userProfile.permissions,
      loadingRowsId: state => state.issue_table.loadingRowsId,
    }),

    isCanManageLinks() {
      let res = false;
      if ('can_manage_links' in this.permissions) {
        res = this.permissions.can_manage_links;
      }

      return res;
    },

    classColorStatus() {
      let res = 'processing';

      if (this.status.indexOf('_RED') >= 0) {
        res = 'denied';
      }

      return res;
    },

    textStatus() {
      // Функция написана в расчете на то, что будут переводы этих фраз
      let res = '';

      if (this.status === 'MULTIPLE_SKU_RED') {
        res = this.$t('MULTIPLE_SKU_RED');
      } else if (this.status === 'DIFFERENT_RPC_RED') {
        res = this.$t('DIFFERENT_RPC_RED');
      } else if (this.status === 'MULTIPLE_SKU_GREY') {
        res = this.$t('MULTIPLE_SKU_GREY');
      } else if (this.status === 'DIFFERENT_RPC_GREY') {
        res = this.$t('DIFFERENT_RPC_GREY');
      } else if (this.status === 'TO_DECIDE') {
        res = this.$t('TO_DECIDE');
      }

      return res;
    },
  },

  watch: {
    isCanManageLinks: {
      immediate: true,
      handler(val) {
        this.disabledGlobal = !val;
      },
    },

    status: {
      immediate: true,
      handler(val) {
        if (this.status === 'MULTIPLE_SKU_RED') {
          this.disabledApprove = true;
          this.disabledDisapprove = false;
        } else if (this.status === 'DIFFERENT_RPC_RED') {
          this.disabledApprove = true;
          this.disabledDisapprove = false;
        } else if (this.status === 'MULTIPLE_SKU_GREY') {
          this.disabledApprove = false;
          this.disabledDisapprove = false;
        } else if (this.status === 'DIFFERENT_RPC_GREY') {
          this.disabledApprove = false;
          this.disabledDisapprove = false;
        } else if (this.status === 'TO_DECIDE') {
          this.disabledApprove = false;
          this.disabledDisapprove = false;
        }
      },
    },
  },

  methods: {
    ...mapActions([
      'A_SEND_APPROVE',
      'A_SEND_DENIED',
    ]),

    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    async sendApprove() {
      this.disabledGlobal = true;

      await this.A_SEND_APPROVE({
        items: this.list,
        table: 'issue_table',
      });

      // Закрываем модалку сравнения
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isOpen',
        data: false,
      });

      this.disabledGlobal = false;

      this.$emit('change');
    },

    async sendDenied() {
      this.disabledGlobal = true;

      await this.A_SEND_DENIED({
        items: this.list,
        table: 'issue_table',
      });

      // Закрываем модалку сравнения
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isOpen',
        data: false,
      });

      this.disabledGlobal = false;

      this.$emit('change');
    },
  },
};
</script>

<style lang="stylus" scoped>
.row
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center

  .action_button
    display: flex;
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 6px
    width: 24px
    height: 24px
    background: $white
    border: 1px solid $blue-grey-100
    border-radius: 4px
    margin-left: 8px
    &:hover
      .fa-circle-check
        color: $green-300
      .fa-circle-xmark
        color: $red-300
    &:disabled
      .fa-circle-check
        color: $blue-grey-200
      .fa-circle-xmark
        color: $blue-grey-200

.status
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 4px 12px
  border-radius: 50px
  color: $blue-grey-700
  font-weight: 500
  min-width: 110px
  &.denied
    background-color: $red-50
  &.processing
    background-color: $grey-100
</style>