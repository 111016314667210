<script>
export default {
  name: 'mixin-choose-all',

  data() {
    return {
      $selectAll: false,
      $selectedId: [],
    };
  },

  methods: {
    // клик на товаре
    $changeChoice(id, count) {
      if (this.$data.$selectedId.includes(id)) {
        const index = this.$data.$selectedId.indexOf(id);
        this.$data.$selectedId.splice(index, 1);
      } else {
        this.$data.$selectedId.push(id);
      }

      // Редкие кейсы
      // Если кол-во снятых\выделенных элементов ($selectedId) будет равно кол-ву эл-ов в таблице
      if (count === this.$data.$selectedId.length) {
        this.$chooseAll();
      } else if (this.$data.$selectedId.length === 0 && this.$data.$selectAll) {
        // Если человек снял руками все эл-ты в таблице, когда this.selectAll был равен true
        this.$chooseAll();
      }
    },

    // выбрать все \ убрать все
    $chooseAll() {
      this.$data.$selectAll = !this.$data.$selectAll;
      this.$data.$selectedId = [];
    },

    // определение выбран эл-т или нет
    $activityValue(id) {
      let res = false;

      if (this.$data.$selectAll) {
        res = !this.$data.$selectedId.includes(id);
      } else {
        res = this.$data.$selectedId.includes(id);
      }

      return res;
    },

    // сброс выбранных и галочки
    $resetSelected() {
      this.$data.$selectedId = [];
      this.$data.$selectAll = false;
    },
  },
};
</script>