import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import i18n from './js/i18n';
import { sync } from 'vuex-router-sync';
import pimColors from '@/styles/variables/colors.json';

import CTablev3 from '@/assets/ctablev3/index';
import Panel from '@/assets/Panel';
import Checkbox from '@/assets/Checkbox';
import Paginate from 'vuejs-paginate';
import LinkLabel from '@/assets/LinkLabel';

import VueRouter from 'vue-router';
import PrimeVue from 'primevue/config';
import VueClipboard from 'vue-clipboard2';
import VueEasyLightbox from 'vue-easy-lightbox';
import VueTippy, { TippyComponent } from 'vue-tippy';

import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';

import 'primeicons/primeicons.css';
import 'tippy.js/themes/light.css';
import '@/styles/main.stylus';

import '@/static/font/fontawesome-pro-6/css/all.min.css';

Vue.config.productionTip = false;

Vue.prototype.$pimColors = pimColors;

Vue.use(CTablev3, { store });
Vue.use(VueRouter);
Vue.use(PrimeVue);
Vue.use(VueClipboard);
Vue.use(VueEasyLightbox);
Vue.use(VueTippy);

sync(store, router);

Vue.component('Dropdown', Dropdown);
Vue.component('InputText', InputText);
Vue.component('InputSwitch', InputSwitch);
Vue.component('Calendar', Calendar);
Vue.component('MultiSelect', MultiSelect);
Vue.component('tippy', TippyComponent);
Vue.component('paginate', Paginate);
Vue.component('panel', Panel);
Vue.component('checkbox', Checkbox);
Vue.component('link-label', LinkLabel);

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');