<template>
  <p
    class="link-label">
    <a
      v-if="url"
      :href="url"
      target="_blank">
      {{ label }}
    </a>
    <span v-else>
      {{ label }}
    </span>
  </p>
</template>

<script>
export default {
  name: 'LinkLabel',

  props: {
    label: {
      type: String,
      default: '',
    },

    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus" scoped>
.link-label
  display: inline-block
  margin: 0
</style>