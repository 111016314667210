export default {
  functional: true,

  props: {
    column: Object,
    row: Object,
    index: Number,
    colspan: Number,
  },

  render(createElement, context) {
    const props = context.props;
    let content = props.row[props.column.name] || '–';

    // если передали только функцию, значит это функция-темплейт
    // по факту прокинули слот во внутренний компонент основного компонента
    if (props.column.template) {
      content = props.column.template({ ...props.row, table_index: props.index }) || '–';
    }

    if (typeof props.column.show === 'boolean' && !props.column.show) {
      return;
    }

    if (props.colspan) {
      context.data.attrs.colspan = props.colspan;
    }

    // кастомный класс
    if (props.column.customClass) {
      context.data.class.push(`${props.column.customClass}`);
    }

    if (props.column.width) {
      context.data.attrs.width = props.column.width;
    }

    return createElement(
      'td',
      context.data,
      content
    );
  },
};

/*
Как в будущем минифицировать весь этот функциональный компонент
Всю основную логику можно вытащить в обертку

<some-component>
  <div slot-scope="{ vnodes }">
    <vnodes :vnodes="vnodes"/>
  </div>
</some-component>

components: {
  Vnodes: {
    functional: true,
    render: (h, ctx) => ctx.props.vnodes
  }
}
*/