<template>
  <div :class="'bfb-group-btn'">
    <template v-for="(item, index) of list">
      <button
        v-show="item.show !== false"
        :key="index"
        :class="['btn',
                 'bfb-group-btn__el',
                 { active: localActive === item.key && leaveSelected },
                 { disable: item.disable },
                 itemClass]"
        @click="checkBtn(item)">
        <!-- возможность прокидывания своего содержимого -->
        <slot :name="item.key">
          <span class="bfb-group-btn__title">
            {{ item.name }}
          </span>
        </slot>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'group-btn',

  props: {
    // номер активной кнопки при прогрузке страницы
    // не обязательно менять этот флаг после каждого клика (он будет меняться сам в компоненте)
    keyActiveBtn: {
      type: [String, Number, Boolean],
      default: null,
    },

    // пример
    /*
    {name: 111, key: 'one', disable: true },
    {name: 222, key: 'two', show: false },
    {name: 333, key: 'three', }
    */
    list: {
      type: Array,
      default: () => ([]),
    },

    itemClass: {
      type: String,
      default: '',
    },

    // оставлять тапнутые кнопки в нажатом состоянии
    leaveSelected: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      localActive: null,
    };
  },

  watch: {
    keyActiveBtn: {
      immediate: true,
      handler(val) {
        this.localActive = val;
      },
    },
  },

  methods: {
    checkBtn(obj) {
      if (obj.disable !== true) {
        this.localActive = obj.key;
        this.$emit('change', obj.key);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.bfb-group-btn
  display: flex
  justify-content: center
  align-items: stretch
  padding: 8px 20px
  font-weight: 500
  &__el
    display: flex
    align-items: center
    align-content: center
    overflow: hidden
    padding: 2px 8px
    font-size: 14px
    line-height: 1.5
    background-color: transparent
    border-color: transparent
    cursor: pointer
    user-select: none
    margin: 0 !important
    border-radius: 0
    color: $blue-grey-400
    font-weight: 500
    &.active
      color: $blue-700
    &.disable
      cursor: not-allowed
      opacity: 0.5
      // можно юзать это - но оно не поддерживается на IE < 11
      // pointer-events: none;
  &__title
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
</style>