<template>
  <div class="goods-group">
    <div class="items-top">
      <p class="title">
        {{ itemsTitle }}
      </p>

      <div
        v-if="(type === 'candidatesPim' || type === 'candidatesStore') && !isCanOnlySee"
        class="switchMethod">
        <i class="fa-solid fa-text-size" />
        <input-switch
          v-model="isImageAlgorithm"
          @change="changeSearchAlgorithm" />
        <i class="fa-solid fa-images" />
      </div>

      <div
        v-if="(type === 'candidatesPim' || type === 'candidatesStore') && goods.results.length && !isCanOnlySee"
        v-tippy="{
          theme: 'light',
          delay: [500, 0],
          arrow: true,
          arrowType: 'round',
          animation: 'fade',
          content: this.$t('noCandidates')
        }"
        :class="['btnNoCandidates', {disable: disableNoCandidates}]"
        @click="clickNoCandidates">
        <i class="far fa-comment-dots" />
      </div>

      <goods-pagination
        v-if="goods.pages"
        :page="page"
        :pages="pages"
        @fetchPage="$emit('fetchPage', $event)" />
    </div>
    <goods-search
      :type="type"
      :store-id="storeId"
      :disabled-search="disabledSearch"
      :value="filters[keyFilters + 'sub_term'] || ''"
      @searchLocal="$emit('searchLocal', $event)" />
    <div
      v-if="goods.results && goods.results.length"
      class="items">
      <goods-item
        v-for="item of goods.results"
        :key="item.id"
        :item="item"
        :type="type"
        :key-filters="keyFilters"
        @setSelectedIdGoods="$emit('setSelectedIdGoods', $event)"
        @getCompareItems="$emit('getCompareItems', $event)" />
    </div>
  </div>
</template>

<script>
import queryFunction from '@/js/queryFunction';
import GoodsItem from '@/components/Goods/GoodsItem';
import GoodsPagination from '@/components/Goods/GoodsPagination';
import GoodsSearch from '@/components/Goods/GoodsSearch';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'GoodsGroup',

  components: {
    GoodsItem,
    GoodsPagination,
    GoodsSearch,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    keyFilters: {
      type: String,
      required: true,
    },

    goods: {
      type: Object,
      default: () => ({}),
    },

    type: {
      type: String,
      default: '',
    },

    storeId: {
      type: Number,
      default: 0,
    },

    disabledSearch: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      disableNoCandidates: false,
      isImageAlgorithm: false,
    };
  },

  computed: {
    ...mapState([
      'filters',
    ]),

    ...mapState({
      page: state => state.filters.page,

      pages: state => state.mainGoods.pages,
      isCanOnlySee: state => state.isCanOnlySee,

      isImageAlgorithmStore(state) {
        return state.filters[this.keyFilters + 'search_algorithm'];
      },
    }),

    selectedId() {
      return this.filters.selectedId;
    },

    itemsTitle() {
      return `${this.title} (${queryFunction.tarnsformCount(this.goods.count) || queryFunction.tarnsformCount(this.goods.results.length)})`;
    },
  },

  watch: {
    selectedId: {
      immediate: true,
      handler() {
        this.disableNoCandidates = false;
      },
    },

    isImageAlgorithmStore: {
      immediate: true,
      handler(val) {
        this.isImageAlgorithm = val === 'image';
      },
    },
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    clickNoCandidates() {
      if (!this.disableNoCandidates) {
        this.$emit('clickNoCandidates');
        this.disableNoCandidates = true;
      }
    },

    changeSearchAlgorithm() {
      this.M_MUTATE_BY_KEY({
        key: `filters.${this.keyFilters}search_algorithm`,
        data: this.isImageAlgorithm ? 'image' : 'name',
        create: true,
      });

      if (this.selectedId) {
        this.$emit('changeSearchAlgorithm');
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.goods-group
  display: flex
  flex-direction: column
  box-sizing: border-box
  width: 33%
  height: 100%
  min-width: 450px
  padding: 16px 10px
  background: $grey-50
  border-radius: 4px

.items-top
  display: flex
  align-content: center
  justify-content: space-between
  font-weight: 500
  font-size: 16px
  line-height: 20px
  color: $blue-grey-400
  margin-bottom: 24px
  min-height: 30px

.title
  padding: 0
  margin: 0
  line-height: 2
  flex-grow: 1

.items
  flex: 1 0 0
  overflow: auto

.btnNoCandidates
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 6px
  margin-left: 10px
  background: $grey-50
  border: 1px solid $grey-200
  box-sizing: border-box
  border-radius: 4px
  cursor: pointer
  &.disable
   cursor: default
   opacity: 0.2

.switchMethod
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  .p-inputswitch
    margin: 0px 5px
</style>