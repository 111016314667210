import { vars } from '@/js/additions';

const mode = process.env.NODE_ENV;
const staticForUrl = mode === 'development' ? '/' : '/static/';

export const RPC2SKU = 'rpc2sku';
export const SKU2RPC = 'sku2rpc';
export const CANDIDATES_PIM = 'candidatesPim';
export const LINKED = 'linked';
export const CANDIDATES_STORE = 'candidatesStore';
export const LOADING = 'loading';
export const PER_PAGE = 30;
export const STATIC_URL = vars.linker_host + staticForUrl;
export const NO_IMAGE = STATIC_URL + 'no-image.png';
export const ORIGINAL_CACHED = 'original_cached';
export const ORIGINAL = 'original';
export const THUMBNAIL_CACHED = 'thumbnail_cached';