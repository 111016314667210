<template>
  <div
    :data-goods="`${type}_${item.id}`"
    class="item"
    :class="[selected, linked, { approved: isApproved }]"
    @click="itemClick(item.id)">
    <header
      v-if="type !== RPC2SKU && type !== SKU2RPC && !isLoading"
      class="header">
      <rating-box :match="item.distance_pct" />
      <linker-switch
        :status="type === LINKED ? true : item.is_linked"
        :type="type"
        :current-item-id="item.id"
        :distance="item.distance"
        :distance-pct="item.distance_pct"
        :position="item.position"
        :is-approved="isApproved"
        :is-linked="isLinked"
        :key-filters="keyFilters"
        @click.stop />

      <ask
        :current-item-id="item.id"
        :distance="item.distance"
        :distance-pct="item.distance_pct"
        :position="item.position"
        :is-approved="isApproved"
        :key-filters="keyFilters"
        :is-linked="isLinked" />
    </header>
    <div class="body">
      <div
        class="images"
        @click.stop>
        <skeleton
          v-if="isLoading"
          width="120px"
          height="130px" />
        <goods-gallery
          v-else-if="item.images"
          :images="item.images" />
      </div>
      <div class="content">
        <p class="title">
          <skeleton v-if="isLoading" />
          <template v-else>
            <div class="title-text">
              <span>{{ item.name }}</span><span
                v-clipboard:copy="item.name"
                class="copy"
                @click.stop>
                <i class="pi pi-copy" /></span>
            </div>
          </template>
        </p>

        <table class="options">
          <tr
            v-for="(el, key) in options"
            :key="key"
            class="option">
            <td class="option-label">
              <skeleton
                v-if="isLoading"
                width="60px" />
              <template v-else>
                {{ key }}:
              </template>
            </td>
            <td class="option-value">
              <div class="option-wrapper">
                <div class="option-magic">
                  <skeleton v-if="isLoading" />
                  <template v-else>
                    <span class="value">
                      {{ el.val || '-' }}
                    </span>
                    <span
                      v-if="el.copy"
                      v-clipboard:copy="el.copy"
                      class="copy"
                      @click.stop>
                      <i class="pi pi-copy" />
                    </span>
                  </template>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { RPC2SKU, SKU2RPC, CANDIDATES_STORE, LOADING, CANDIDATES_PIM, LINKED } from '@/js/constants';
import { mapState } from 'vuex';
import queryFunction from '@/js/queryFunction';

import Skeleton from 'primevue/skeleton';
import GoodsGallery from '@/components/Goods/GoodsGallery';
import RatingBox from '@/assets/RatingBox';
import LinkerSwitch from '@/assets/LinkerSwitch';
import Ask from '@/assets/Ask';

export default {
  name: 'GoodsItem',

  components: {
    Skeleton,
    GoodsGallery,
    RatingBox,
    LinkerSwitch,
    Ask,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: '',
    },

    // имя ключа для отправки Алгоритма поиска
    keyFilters: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      LINKED,
      RPC2SKU,
      SKU2RPC,
    };
  },

  computed: {
    ...mapState([
      'filters',
    ]),

    isLoading() {
      return this.item.type === LOADING;
    },

    selected() {
      return { selected: this.filters.selectedId === this.item.id };
    },

    linked() {
      return { linked: this.type === LINKED || this.item.is_linked };
    },

    isApproved() {
      return this.item.is_approved;
    },

    isLinked() {
      return this.item.is_linked;
    },

    options() {
      const res = {};
      const el = this.item;

      if (this.isPIM) {
        res.RPC = { val: el.rpc, copy: el.rpc };
        res[this.$t('brand')] = { val: el.brand, copy: el.brand };
        res[this.$t('images')] = { val: el.images?.length, copy: el.images?.length };

        res[this.$t('actualPrice')] = {
          val: `${el.price_current} ${el.store_currency}`,
          copy: `${el.price_current} ${el.store_currency}`,
        };

        res[this.$t('regularPrice')] = {
          val: `${el.price_original} ${el.store_currency}`,
          copy: `${el.price_original} ${el.store_currency}`,
        };

        res[this.$t('store')] = {
          val: queryFunction.cropUrl(el.url),
          copy: el.url,
        };
      } else {
        res.SKU = { val: el.sku, copy: el.sku };
        res[this.$t('brand')] = { val: el.brand, copy: el.brand };
        res[this.$t('images')] = { val: el.images?.length, copy: el.images?.length };
        res.PIM = { val: queryFunction.cropUrl(el.pim_url), copy: el.pim_url };
      }

      return res;
    },

    isPIM() {
      return this.type === RPC2SKU || this.type === CANDIDATES_STORE;
    },
  },

  methods: {
    linkItem(data) {
      this.$emit('setLinkItem', {
        id: this.item.id,
        value: data,
      });
    },

    itemClick(id) {
      const checkTypes = [CANDIDATES_PIM, CANDIDATES_STORE, LINKED].includes(this.type);

      if (checkTypes) {
        this.$emit('getCompareItems', {
          id,
          match: this.item.distance_pct,
          linked: this.type === LINKED ? true : this.item.is_linked,
          type: this.type,
          distance: this.item.distance,
          position: this.item.position,
          is_linked: this.item.is_linked,
          is_approved: this.item.is_approved,
          keyFilters: this.keyFilters,
        });
      } else {
        // ткнули по блоку из 1 столбца
        this.$emit('setSelectedIdGoods', {
          id,
          type: this.type,
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.item
  position: relative
  min-width: 0
  padding: 19px 11px
  margin: 0 0 24px 0
  background: $white
  border: 1px solid $grey-200
  border-radius: 4px
  transition: filter linear 0.2s, background linear 0.2s, border linear 0.2s
  cursor: pointer
  &:last-child
    margin-bottom: 0
  &.not-clicked
    &:hover
      background: white
      transform: none
  &.selected
    background: $orange-50
  &.linked
    background: $orange-50
    &.approved
      background: $green-50

.header
  position: relative
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  padding: 8px 11px
  margin: -19px -11px 20px -11px
  border-bottom: 1px solid $blue-grey-100

.body
  display: flex
  justify-content: space-between

.overlay-click
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: block

.content
  flex: 1 0 0
  min-width: 0
  padding-left: 20px

.copy
  display: inline-block
  margin-left: 3px
  visibility: hidden
  cursor: pointer
  color: $grey-700

.title
  position: relative
  padding: 0
  margin: 0 0 16px 0
  font-weight: 500
  font-size: 14px
  line-height: 16px
  color: $grey-700
  &:hover .copy
    visibility: visible

.title-text
  display: inline-block

.options
  width: 100%
  max-width: 320px

.option
  td
    padding-bottom: 4px
  &:last-child
    td
      padding-bottom: 0

.option-label
  user-select: none
  pointer-events: none
  padding-right: 32px
  white-space: nowrap

.option-value
  width: 100%
  &:hover .copy
    visibility: visible

.option-wrapper
  position: relative
  height: 17px

.option-magic
  position: absolute
  top: 0
  left: 0
  width: 100%
  white-space: nowrap
  display: flex
  a
    position: relative
    overflow: hidden
    text-overflow: ellipsis

.value
  overflow: hidden
  text-overflow: ellipsis
</style>