import { render, staticRenderFns } from "./GoodsPagination.vue?vue&type=template&id=fc71f75a&scoped=true&"
import script from "./GoodsPagination.vue?vue&type=script&lang=js&"
export * from "./GoodsPagination.vue?vue&type=script&lang=js&"
import style0 from "./GoodsPagination.vue?vue&type=style&index=0&id=fc71f75a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc71f75a",
  null
  
)

export default component.exports