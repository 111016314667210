<template>
  <div class="info">
    <div class="photo">
      <div class="photo-main">
        <img
          :src="obj.image_table || emptySrc"
          class="photo-img"
          :onerror="emptySrc"
          @click="showMain">
      </div>
    </div>

    <div class="info__description">
      <p
        v-if="isRpc"
        class="info__text">
        <span>RPC: </span>

        <link-label
          :label="obj.rpc"
          :url="obj.url" />
      </p>

      <p
        v-if="isSku"
        class="info__text">
        <span>SKU: </span>

        <link-label
          :label="obj.sku"
          :url="obj.url" />
      </p>

      <p class="info__text clip">
        <slot name="title">
          <span
            class="pointer name"
            @click="$emit('showModalCompare')">
            {{ obj.title }}
          </span>
        </slot>
      </p>

      <p class="info__text">
        <slot name="brand">
          {{ obj.brand }}
        </slot>
      </p>
    </div>
  </div>
</template>

<script>
import ImageBox from '@/assets/ImageBox';
import { NO_IMAGE } from '@/js/constants';
import { mapMutations } from 'vuex';

export default {
  name: 'product-info',

  components: {
    ImageBox,
  },

  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      mainImage: null,
      mainImageKey: 0,
      mainImgLarge: null,
    };
  },

  computed: {
    emptySrc() {
      return `this.src='${NO_IMAGE}';`;
    },

    isRpc() {
      return 'rpc' in this.obj;
    },

    isSku() {
      return 'sku' in this.obj;
    },
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    showMain() {
      this.M_MUTATE_BY_KEY({
        key: 'mainImgLarge',
        data: this.obj.image_preview || this.emptySrc,
      });
    },

    handleHide() {
      this.mainImgLarge = null;
    },
  },
};
</script>

<style lang="stylus" scoped>
.info
  display: flex
  &__preview
    margin-right: 16px
  &__description
    display: flex
    flex-direction: column
    padding-left: 15px
    font-weight: 400
    > p
      margin: 0
      margin-bottom: 5px
  &__text
    margin: 0
    margin-bottom: 5px
    > span
      color: $blue-grey-700
      &.name
        font-weight: 500
  &__sku
    display: inline-block

.photo
  flex: 0 0 100px

.photo-main
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  height: 110px
  background: $white
  border: 1px solid $blue-grey-100
  border-radius: 4px

.photo-img
  position: relative
  max-width: 100%
  max-height: 100%

::v-deep .previewImg
  max-width: 100%
  max-height: 100%

.disabled
  cursor: default
  pointer-events: none

.pointer
  cursor:pointer
</style>