<template>
  <div v-if="false">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'c-table-column',

  props: {
    label: String,
    width: String,
    customClass: String,
    name: String,

    sort: {
      type: Boolean,
      default: true,
    },

    filter: {
      type: Object,
      default: () => ({}),
    },

    show: {
      type: Boolean,
      default: true,
    },

    fixed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>