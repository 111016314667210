import moment from 'moment';

const DATE_FORMAT_YMD = 'YYYY-MM-DD';

// настройки календаря
const LIST_DATES = [
  {
    // Последние 30 дней
    DATE_FROM: moment().subtract(30, 'days').format(DATE_FORMAT_YMD),
    DATE_TO: moment().subtract(1, 'days').format(DATE_FORMAT_YMD),
  },
];

export default LIST_DATES;