<template>
  <div class="app-main cols">
    <goods-group
      :key="filters.type"
      :goods="mainGoods"
      :title="mainTitle"
      :type="filters.type"
      :key-filters="''"
      :disabled-search="false"
      @setSelectedIdGoods="clickMainGoods"
      @searchLocal="searchLocal"
      @fetchPage="fetchPage" />

    <template v-if="filters.type === RPC2SKU">
      <goods-group
        :key="`Candidates_${updateColumsKey}`"
        :goods="rpc2skuCandidates"
        type="candidatesPim"
        :title="$t('candidates')"
        :key-filters="'candidate_'"
        :disabled-search="!filters.selectedId"
        @clickNoCandidates="clickNoCandidates"
        @getCompareItems="getCompareItems"
        @changeSearchAlgorithm="GET_RPC2SKU_CANDIDATES()"
        @searchLocal="searchLocal" />
      <goods-group
        :key="`Linked_${updateColumsKey}`"
        :goods="rpc2skuLinked"
        type="linked"
        :title="$t('linked')"
        :key-filters="'linked_'"
        :disabled-search="!filters.selectedId"
        @getCompareItems="getCompareItems"
        @searchLocal="searchLocal" />
    </template>

    <template v-else>
      <goods-group
        v-for="store in sku2rpcStores"
        :key="store.id"
        :goods="store"
        type="candidatesStore"
        :title="`${store.name} ${$t('candidates').toLowerCase()}`"
        :store-id="store.id"
        :key-filters="`store_${store.id}_`"
        :disabled-search="!filters.selectedId"
        @clickNoCandidates="clickNoCandidates({store: store.name})"
        @getCompareItems="getCompareItems"
        @changeSearchAlgorithm="GET_SKU2RPC_CANDIDATE({store: store.id})"
        @searchLocal="searchLocal" />
    </template>
  </div>
</template>

<script>
import { RPC2SKU, SKU2RPC, CANDIDATES_PIM, LINKED, CANDIDATES_STORE } from '@/js/constants';
import GoodsGroup from '@/components/Goods/GoodsGroup';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'ProductsBody',

  components: {
    GoodsGroup,
  },

  data() {
    return {
      RPC2SKU,
    };
  },

  computed: {
    ...mapState([
      'updateColumsKey',
      'filters',
      'mainGoods',
      'rpc2skuCandidates',
      'rpc2skuLinked',
      'sku2rpcStores',
    ]),

    mainTitle() {
      return this.filters.type === RPC2SKU ? this.$t('prodFromStore') : this.$t('prodFromPIM');
    },
  },

  watch: {
    // Если updateColumsKey обновляем столбцы
    updateColumsKey: {
      handler(val) {
        if (val) {
          this.clickMainGoods({ id: this.filters.selectedId, type: this.filters.type });
        }
      },
    },
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    ...mapActions([
      'GET_RPC2SKU_CANDIDATES',
      'GET_LINKED_GOODS',
      'GET_SKU2RPC_CANDIDATE',
      'GET_GOOD_INFO',
      'GET_GOOD_INFO_PIM',
      'SEND_NO_CANDIDATES',
    ]),

    // Клик по 1 столбцу с товарами
    clickMainGoods({ id, type }) {
      // Актуализируем selectedId в стор
      this.M_MUTATE_BY_KEY({
        key: 'filters.selectedId',
        data: +id,
        create: true,
      });

      if (type === RPC2SKU) {
        this.loadPageRpc2Sku();
      } else {
        this.loadPageSku2Rpc();
      }

      this.setLeftSideModal(id, type);
    },

    // загрузка колонок для Rpc2Sku
    async loadPageRpc2Sku() {
      await Promise.allSettled(
        [
          // загрузка колонки Candidates
          this.GET_RPC2SKU_CANDIDATES(),
          // загрузка колонки LINKED
          this.GET_LINKED_GOODS(),
        ]
      );
    },

    // загрузка колонок для Sku2Rpc
    async loadPageSku2Rpc() {
      this.filters.stores.forEach(async (store) => {
        await this.GET_SKU2RPC_CANDIDATE({ store });
      });
    },

    // Получаем правую часть для модалки
    async getCompareItems({
      id,
      match,
      linked,
      type,
      distance,
      position,
      is_approved,
      is_linked,
      keyFilters,
    }) {
      // заполняем актуальные статус свитчера для модалки
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.statusLink',
        data: {
          id,
          match,
          linked,
          type,
          distance,
          position,
          is_approved,
          is_linked,
          keyFilters,
        },
      });

      this.setRightModal(id, type);
    },

    async setRightModal(id, type) {
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isLoading',
        data: true,
      });

      this.filters.type === RPC2SKU
        ? await this.GET_GOOD_INFO_PIM({ id, type })
        : await this.GET_GOOD_INFO({ id, type });

      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isLoading',
        data: false,
      });

      // данные обновились можно показывать модалку
      this.M_MUTATE_BY_KEY({
        key: 'modalCompare.isOpen',
        data: true,
      });
    },

    // загрузка левой части модалки
    async setLeftSideModal(id, type) {
      this.filters.type === RPC2SKU
        ? await this.GET_GOOD_INFO({ id, type })
        : await this.GET_GOOD_INFO_PIM({ id, type });
    },

    // Поиск
    async searchLocal({ type, val, store }) {
      // Поиск в левом стоблце
      if (type === RPC2SKU || type === SKU2RPC) {
        this.M_MUTATE_BY_KEY({
          key: 'filters.sub_term',
          data: val,
          create: true,
        });

        // сбрасываем пагинацию
        this.M_MUTATE_BY_KEY({
          key: 'filters.page',
          data: 1,
        });

        this.$emit('loadMainGoods', {
          ...this.filters,
          isFirstColumn: true,
        });

        // Поиск среди кандидатов RPC2SKU
      } else if (type === CANDIDATES_PIM) {
        this.M_MUTATE_BY_KEY({
          key: 'filters.candidate_sub_term',
          data: val,
          create: true,
        });

        await this.GET_RPC2SKU_CANDIDATES();
      // Поиск среди связанных
      } else if (type === LINKED) {
        this.M_MUTATE_BY_KEY({
          key: 'filters.linked_sub_term',
          data: val,
          create: true,
        });

        await this.GET_LINKED_GOODS();
      // Поиск кандидатов магазинов SKU2RPC
      } else if (type === CANDIDATES_STORE) {
        this.M_MUTATE_BY_KEY({
          key: 'filters.' + ['store_' + store + '_sub_term'],
          data: val,
          create: true,
        });

        await this.GET_SKU2RPC_CANDIDATE({ store });
      }
    },

    // Пагинация
    fetchPage(value) {
      this.M_MUTATE_BY_KEY({
        key: 'filters.page',
        data: this.filters.page + value,
      });

      this.$emit('loadMainGoods', {
        ...this.filters,
        isFirstColumn: true,
      });
    },

    // Сообщаем что нет подходящих кандидатов
    clickNoCandidates(option) {
      const body = { ...option };

      if (this.filters.type === SKU2RPC) {
        body.pim_item_id = this.filters.selectedId;
      } else if (this.filters.type === RPC2SKU) {
        body.store_item_id = this.filters.selectedId;
      }

      this.SEND_NO_CANDIDATES({
        body,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.cols
  display: flex
  gap: 32px
  height: 100%
  overflow: auto
</style>