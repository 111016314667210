<template>
  <img
    :src="imgUrl"
    :class="{notActive: !copyImage.type}"
    alt=""
    @error="setImgUrl"
    @click="eventClick">
</template>

<script>
import { NO_IMAGE, THUMBNAIL_CACHED, ORIGINAL_CACHED, ORIGINAL } from '@/js/constants';

export default {
  name: 'ImageBox',

  props: {
    image: {
      type: Object,
      default: () => ({ empty: NO_IMAGE }),
    },

    type: {
      type: String,
      default: 'thumbnail',
    },
  },

  data() {
    return {
      types: this.type === 'original'
        ? [ORIGINAL_CACHED, ORIGINAL, THUMBNAIL_CACHED]
        : [THUMBNAIL_CACHED, ORIGINAL_CACHED, ORIGINAL],
      copyImage: {},
    };
  },

  computed: {
    imgUrl() {
      return this.copyImage.type
        ? this.copyImage[this.copyImage.type]
        : NO_IMAGE;
    },
  },

  created() {
    this.copyImage = {
      ...this.image,
      error: false,
      type: null,
    };
    this.setImgUrl();
  },

  methods: {
    setImgUrl() {
      this.copyImage.type = this.types.shift();
      this.$emit('setImage', this.imgUrl);
      if (this.copyImage.type && !this.copyImage[this.copyImage.type]) {
        this.setImgUrl();
      }
    },

    eventClick() {
      if (this.copyImage.type) {
        this.$emit('clicked', {
          image: this.copyImage,
          src: this.copyImage[this.copyImage.type],
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
img
  cursor pointer

.notActive
  cursor default
  pointer-events none
</style>