export default {
  // mutations
  m: {
    SET_PAGE: 'SET_PAGE',
    SET_SEARCH: 'SET_SEARCH',
    SET_FILTERS: 'SET_FILTERS',
    SET: 'SET',
  },

  // actions
  a: {
    CLEAR_STATE: 'CLEAR_STATE',
    TOGGLE_CHART: 'TOGGLE_CHART',
  },

  // getters
  g: {
    GET_NAME: 'GET_NAME',
    GET_PAGE: 'GET_PAGE',
    GET_SEARCH: 'GET_SEARCH',
    GET_FILTER: 'GET_FILTER',
    GET_SORT: 'GET_SORT',
    GET_CHARTS: 'GET_CHARTS',
  },
};