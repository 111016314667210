import CTable from './CTable';
import CTableColumn from './CTableColumn';

const Plugin = {
  install(Vue, options = {}) {
    const store = options.store;

    // Плагин устанавливается только 1 раз
    if (this.installed) return;

    // Проверка на стор
    if (!store) {
      throw new Error('Please provide vuex store.');
    }

    this.installed = true;

    // Регистирируем в сторе отдельный модуль c-table
    store.registerModule('c-table-v3', {
      namespaced: true,
    });

    Vue.component('c-table-v3', { ...CTable, store });
    Vue.component('c-table-column-v3', CTableColumn);
  },
};

export default Plugin;